import React from 'react'
import Column from './column'
import RowDescriptions from './row-descriptions'
import css from './styles.module.css'

function PackBody ({ pack, timezone }) {
  const columns = Object.keys(pack.cavities)
    .filter(key => key.indexOf('column') !== -1 && key !== 'column4')
    .sort()
    .map(key => <Column column={pack.cavities[key]} key={key} columnKey={key} timezone={timezone} type={pack.type} />)

  return (
    <div className={css.packBody}>
      <RowDescriptions startDate={pack.startDate} timezone={timezone} columns={columns} type={pack.type} />
      {columns}
    </div>
  )
}

export default PackBody

import React from 'react'
import moment from 'moment-timezone'
import classnames from 'classnames'
import { Popover } from '@blueprintjs/core'
import css from './styles.module.css'

function getCavityStyle (state) {
  switch (state) {
    case 'early':
    case 'late':
    case 'wrong day':
    case 'unknown':
    case 'no timestamp': return 'wrong'
    case 'ok': return 'ok'
    case 'missed': return 'missed'
    case 'await': return 'await'
    case 'suspended': return 'suspended'
    default: return 'disabled'
  }
}

function TimeText ({ state, dateOpened, timezone }) {
  let text
  switch (state) {
    case 'suspended':
    case 'missed':
    case 'await':
    case 'unknown':
    case 'no timestamp': text = '- - : - -'
      break
    case 'disabled': text = ''
      break
    default: text = moment(dateOpened).tz(timezone).format('hh:mm A')
  }
  return <div className={css.timeText}>{text}</div>
}

function DateText ({ state, dateOpened, dateToOpenFrom, timezone }) {
  let text
  switch (state) {
    case 'missed': text = 'NOT OPENED'
      break
    case 'no timestamp': text = 'OPENED'
      break
    case 'await': text = dateToOpenFrom ? moment(dateToOpenFrom).tz(timezone).format('MMM DD, ddd') : 'NOT OPENED'
      break
    case 'disabled':
    case 'unknown': text = ''
      break
    default: text = moment(dateOpened).tz(timezone).format('MMM DD, ddd')
  }
  return <div className={css.dateText}>{text}</div>
}

function StateLabel ({ state, style, hover }) {
  if (state === 'unknown' && hover) {
    return <Popover boundary='window' targetTagName='div' interactionKind='hover' hoverOpenDelay={0} content={hover}>
      <div className={css.stateLabel}>{state}</div>
    </Popover>
  }
  return <div className={css.stateLabel}>{state}</div>
}

function Cavity ({ cavity, timezone, type }) {
  let state = cavity.status

  if (cavity.isSuspended === true) {
    state = "suspended"
  }
  const style = getCavityStyle(state)

  return (
    <div className={classnames(css.cavity, css[style])}>
      { cavity.userAction && <Popover boundary='window' interactionKind='hover' minimal hoverOpenDelay={0} className={css.userActionPopover} content={
        <div style={{ padding: 10 }}>
          <p><b>User Action: </b>{cavity.userAction.action}</p>
          <div><b>Time: </b>{moment(cavity.userAction.time).tz(timezone).format()}</div>
        </div>}>
        <div className={classnames(css.userAction, cavity.userAction.action === 'Taken' ? css.taken : css.cancel)} /></Popover> }
      <TimeText state={state} dateOpened={cavity.dateOpened} timezone={timezone} />
      <DateText state={state} dateOpened={cavity.dateOpened} timezone={timezone} dateToOpenFrom={type === 'thrifty28days' && cavity.dateToOpenFrom} />
      <StateLabel state={state} hover={cavity.statusComments} />
    </div>
  )
}

export default Cavity

import React from 'react'
import moment from 'moment-timezone'

const NUM_COLUMNS = 4
const NUM_ROWS = 7

const COLUMN_TO_TIME_MAP = {
  column0: 'breakfast',
  column1: 'lunch',
  column2: 'supper',
  column3: 'bedtime'
}

function AdherenceHeatmap ({ packs, from, to }) {
  let stats = {}
  packs.forEach((pack) => {
    const cavities = pack.cavities
    for (let i = 0; i < NUM_COLUMNS; i++) {
      const column = `column${i}`
      for (let j = 0; j < NUM_ROWS; j++) {
        const row = `row${j}`
        const cavity = cavities[column][row]
        if (cavity && cavity.enabled) {
          const openDate = moment(cavity.dateToOpenFrom)
          if (openDate.isAfter(from) && (!to || openDate.isBefore(to))) {
            const weekday = openDate.format('ddddd')
            if (!stats[weekday]) {
              stats[weekday] = {
                breakfast: 0,
                lunch: 0,
                supper: 0,
                bedtime: 0
              }
            }
            if (cavity.status === 'missed') { stats[weekday][COLUMN_TO_TIME_MAP[column]] = stats[weekday][COLUMN_TO_TIME_MAP[column]] + 1 }
          }
        }
      }
    }
  })

  stats = Object.keys(stats).map((k) => ({ weekday: k.slice(0, -1), order: parseInt(k.slice(k.length - 1)), ...stats[k] }))

  stats.sort((a, b) => {
    if(a.order === 0) return 1
    if(b.order === 0) return -1
    return a.order < b.order ? -1 : 1
  })

  const Cell = ({ v }) => <td style={{ background: `rgba(0, 0, 0, ${v / 15})` }}>{v}</td>

  return (
    <table>
      <thead>
        <tr>
          <th>Day of week</th>
          <th>Breakfast</th>
          <th>Lunch</th>
          <th>Dinner</th>
          <th>Bedtime</th>
        </tr>
      </thead>
      <tbody>
        {stats.map(i => <tr key={i.weekday}>
          <td>{i.weekday}</td>
          <Cell v={i.breakfast} />
          <Cell v={i.lunch} />
          <Cell v={i.supper} />
          <Cell v={i.bedtime} />
        </tr>)}
      </tbody>
    </table>
  )
}

export default AdherenceHeatmap

import React, { useState, useEffect } from 'react'
import { Overlay, NonIdealState, ProgressBar, Intent } from '@blueprintjs/core'
import organizationsService from '../../api-services/organizations-service'
import caregiversService from '../../api-services/caregivers-service'
import patientsService from '../../api-services/patients-service'
import packsService from '../../api-services/packs-service'
import gatewaysService from '../../api-services/gateways-service'
import logo from '../../images/cuepath_icon_full_color.png'

function DataManager ({ toaster, isAuthenticated, hasPermissions }) { // BAD approach: `shouldReload` SHOULD FIND A BETTER SOLUTION
  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [stateMessage, setStateMessage] = useState('')
  const [dots, setDots] = useState('.')
  const [progress, setProgress] = useState(0)
  const [progressIntent, setProgressIntent] = useState(Intent.PRIMARY)

  useEffect(() => {
    if (isLoaded && !isAuthenticated) clearData()
    if (!isLoaded && isAuthenticated && hasPermissions) loadData()
  }, [isAuthenticated])

  // reset the dots and move the progress bar every time a the state changes
  useEffect(() => {
    if (!isLoading) setDots('')
    else {
      setDots('.')
      setProgress(progress + 1 / 10)
    }
  }, [stateMessage])

  // while waiting for something to happen within a state, keep putting dots so the user has the notion that something is happening, its is not stuck
  // . -> .. -> ... -> . -> and so on
  useEffect(() => {
    if (!isLoading) return
    if (dots.length > 3) setDots('.')
    else setTimeout(() => setDots(dots + '.'), 1000)
  }, [dots])

  const clearData = () => {
    organizationsService.clear()
    patientsService.clear()
    packsService.clear()
    gatewaysService.clear()
    setIsLoading(false)
    setIsLoaded(false)
    setStateMessage('')
  }

  const loadData = async () => {
    setProgress(0)
    setIsLoading(true)
    setIsLoaded(false)
    try {
      setStateMessage('Loading Organizations')
      await organizationsService.init()
      setStateMessage('Loading Caregivers')
      await caregiversService.init()
      setStateMessage('Loading Patients')
      await patientsService.init()
      setStateMessage('Loading Packs')
      await packsService.init({ fields: '-cavities' })
      setStateMessage('Loading Gateways')
      await gatewaysService.init()
      setStateMessage('Populating Patients')
      patientsService.populate(organizationsService, packsService, gatewaysService, caregiversService)
      setStateMessage('Populating Organizations')
      organizationsService.populate(patientsService)
      setStateMessage('Populating Gateways')
      gatewaysService.populate(patientsService)
      setStateMessage('Finished')
      setIsLoaded(true)
    } catch (err) {
      toaster.show({
        action: { onClick: loadData, text: 'RETRY' },
        intent: Intent.DANGER,
        icon: 'error',
        message: `There was an error when trying to load the data: ${err.message}`,
        timeout: 0
      })
      console.error(`There was an error when trying to load the data: ${err.message}`)
      setStateMessage('Error')
      setProgressIntent(Intent.DANGER)
    }
    setIsLoading(false)
  }

  const sessionProgress = (
    <NonIdealState icon={<img src={logo} alt='logo' style={{ width: '100px', height: '100px' }} />} title={stateMessage && stateMessage + dots} >
      <ProgressBar value={progress} intent={progressIntent} />
    </NonIdealState>
  )

  return (
    <Overlay isOpen={!isLoaded}>
      <div style={{ opacity: '1', backgroundColor: '#fff', width: '100%', height: '100%' }}>
        {isAuthenticated ? sessionProgress : null}
      </div>
    </Overlay>
  )
}

export default DataManager

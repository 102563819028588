import React from 'react'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import SkeletonTableRows from '../../layout/skeleton-table-rows/skeleton-table-rows'
import { sortBy, filterBy } from '../../../utils/tables'

const columns = [
  {
    Header: 'Name',
    id: 'name',
    accessor: ({ _id, name }) => ({ _id, name }),
    Cell: ({ value }) => <Link to={`/organizations/${value._id}`}>{value.name}</Link>,
    sortMethod: sortBy('name'),
    filterMethod: filterBy('name')
  },
  {
    Header: 'Type',
    accessor: 'type'
  },
  {
    Header: 'Salesforce ID',
    accessor: 'salesforceId'
  },
  {
    Header: 'Rx/HCA ID',
    accessor: 'shortId'
  },
  {
    Header: 'Total Patients',
    accessor: 'nPatients'
  }
]

function OrganizationsTable ({ organizations }) {
  if (!organizations) return <SkeletonTableRows nRows={1} nColumns={4} />
  return (
    <ReactTable
      data={organizations}
      defaultSortMethod={sortBy()}
      defaultFilterMethod={filterBy()}
      columns={columns}
      showPageSizeOptions={false}
      defaultPageSize={100}
      minRows={1}
      filterable
      className='-striped -highlight'
    />
  )
}

export default OrganizationsTable

import axios from 'axios'
import config from '../config'
import authenticationServices from '../authentication-services'

const baseURL = config.urls.patientAlerts

const api = axios.create({
  baseURL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json'
  }
})

async function fetchPerPatient (patientId) {
  const headers = {
    Authorization: `Bearer ${authenticationServices.authenticationData.accessToken}`
  }
  const response = await api.get(`/${patientId}`, { headers })
  return response.data
}

export default {
  fetchPerPatient
}

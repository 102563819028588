import React, { useState } from 'react'
import { Button, Intent, Alert } from '@blueprintjs/core'
import { useForm } from 'react-hook-form'
import caregiversService from '../../api-services/caregivers-service'
import patientsService from '../../api-services/patients-service'
import { serverErrorToast, successToast } from '../layout/toaster/toaster'
import FormElement from '../commons/form-group/form-group'
import css from './patients.module.css'

function CaregiverForm ({ setDialogOpen, caregiver, patient }) {
  const [loading, setLoading] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteOpen] = useState(false)
  const { register, handleSubmit, errors } = useForm({
    defaultValues: caregiver ? {
      firstName: caregiver.firstName,
      lastName: caregiver.lastName,
      phone: caregiver.phone,
      email: caregiver.email,
      isNotificationEnabled: caregiver.isNotificationEnabled
    } : {}
  })

  async function deleteCaregiver () {
    try {
      await caregiversService.delete(caregiver._id)
      successToast(`Caregiver was successfully removed`)
      caregiversService.populate()
      patientsService.populate()
    } catch (err) {
      serverErrorToast(err)
    }
    setIsDeleteOpen(false)
    setDialogOpen(false)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    const { email, firstName, lastName, phone } = data

    const body = {
      email,
      firstName,
      lastName,
      phone
    }

    try {
      if (caregiver) await caregiversService.patch(caregiver._id, body)
      else {
        body.patients = [{
          id: patient._id,
          name: `${patient.firstName} ${patient.lastName}`,
          notificationsLevel: 0
        }]
        await caregiversService.post(body)
      }
      caregiversService.populate()
      patientsService.populate()

      successToast(`Caregiver was saved.`)
      setLoading(false)
      setDialogOpen(false)
    } catch (err) {
      serverErrorToast(err)
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='bp3-dialog-header'>
        <span className='bp3-icon-large bp3-icon-inbox' />
        <h4 className='bp3-heading'>{caregiver ? 'Edit Caregiver' : 'Invite Caregiver'}</h4>
        <button onClick={() => setDialogOpen(false)} type='button' aria-label='Close' className='bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross' />
      </div>
      <div className='bp3-dialog-body'>
        <div className={css.row}>
          <div className={css.col}>
            <FormElement label='First Name' inputRef={register({ required: true })} name='firstName' errors={errors} />
          </div>
          <div className={css.col}>
            <FormElement label='Last Name' inputRef={register({ required: true })} name='lastName' errors={errors} />
          </div>
        </div>
        <FormElement label='Email' inputRef={register({ pattern: /\S+@\S+/ })} name='email' errors={errors} />
        <FormElement label='Phone Number' labelInfo='+1 (XXX) XXX-XXXX' inputRef={register({ pattern: /\+1[ ]?\(?[0-9]{3}\)?[ ]?[0-9]{3}-?[0-9]{4}/i })} name='phone' errors={errors} />
      </div>
      <div className='bp3-dialog-footer'>
        <div className='bp3-dialog-footer-actions' style={caregiver && { justifyContent: 'space-between' }}>
          {caregiver && <Button icon='trash' intent={Intent.DANGER} text='Delete' style={{ margin: 0 }} onClick={() => setIsDeleteOpen(true)} />}
          <div>
            <Button onClick={() => setDialogOpen(false)} type='button'>Cancel</Button>
            <Button intent={Intent.SUCCESS} type='submit' icon='saved' loading={loading} >Save</Button>
          </div>
        </div>
      </div>
      {caregiver && <Alert
        cancelButtonText='Cancel'
        confirmButtonText='Delete Caregiver'
        icon='trash'
        intent={Intent.DANGER}
        isOpen={isDeleteDialogOpen}
        onCancel={() => setIsDeleteOpen(false)}
        onConfirm={deleteCaregiver}
      >
        <p>Are you sure you want to permanently delete {caregiver.firstName} {caregiver.lastName} and all related data?</p>
      </Alert>}
    </form>
  )
}

export default CaregiverForm

import React from 'react'
import { Link } from 'react-router-dom'
import { Tag, Intent, Classes } from '@blueprintjs/core'
import ReactTable from 'react-table'
import GatewayDetailsPopover from '../gateway-details-popover/gateway-details-popover'
import SkeletonTableRows from '../../layout/skeleton-table-rows/skeleton-table-rows'
import { sortBy, filterBy } from '../../../utils/tables'
import { getIntentByScore } from '../../../utils/packs'
import authenticationServices from '../../../authentication-services'

const getStatusIntent = status => {
  switch (status) {
    case 'connected': return Intent.SUCCESS
    case 'detached': return Intent.WARNING
    default: return Intent.DANGER
  }
}
function PatientsTable ({ patients }) {
  if (!patients) return <SkeletonTableRows nRows={1} nColumns={13} />

  const { role } = authenticationServices.authenticationData

  let columns = [{
    Header: 'Patient',
    id: 'medId',
    accessor: ({ _id, medId, firstName, lastName }) => ({ _id, medId, name: `${firstName} ${lastName}` }),
    Cell: ({ value }) => (
      <Link to={`/patients/${value._id}`}>
        <span style={{ display: 'block' }} className={Classes.MONOSPACE_TEXT}>{value.medId}</span>
        <span style={{ display: 'block', wordWrap: 'break-word', whiteSpace: 'normal' }}>{value.name}</span>
      </Link>
    ),
    sortMethod: sortBy('name'),
    filterMethod: filterBy(['name', 'medId'])
  }, {
    Header: 'Pharmacy',
    id: 'pharmacy',
    accessor: ({ pharmacy }) => pharmacy,
    Cell: ({ value }) => value ? <Link style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} to={`/organizations/${value._id}`}>{value.name}</Link> : null,
    sortMethod: sortBy('name'),
    filterMethod: filterBy('name')
  }, {
    Header: 'HCA',
    id: 'hca',
    accessor: ({ hca }) => hca,
    Cell: ({ value }) => value ? <Link style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} to={`/organizations/${value._id}`}>{value.name}</Link> : null,
    sortMethod: sortBy('name'),
    filterMethod: filterBy('name')
  }, {
    Header: '7 Days Score',
    id: 'salesforceObject',
    accessor: ({ salesforceObject }) => salesforceObject,
    Cell: ({ value }) => value?.X7_Days_Adherence_Score__c ? <Tag intent={getIntentByScore(value?.X7_Days_Adherence_Score__c,  value?.Adherence_Target__c)} round>{value?.X7_Days_Adherence_Score__c}</Tag> : null
  }, {
    Header: '30 Days Score',
    id: 'salesforceObject',
    accessor: ({ salesforceObject }) => salesforceObject,
    Cell: ({ value }) => value?.X30_Days_Adherence_Score__c ? <Tag intent={getIntentByScore(value?.X30_Days_Adherence_Score__c,  value?.Adherence_Target__c)} round>{value?.X30_Days_Adherence_Score__c}</Tag> : null
  }, {
    Header: 'Gateway Status',
    id: 'gateway.isConnected',
    accessor: ({ gateway, gatewayId }) => gatewayId ? gateway ? gateway.isConnected ? 'connected' : 'disconnected' : 'unknown' : null,
    Cell: ({ value }) => value ? <Tag intent={getStatusIntent(value)} round style={{ borderCollapse: 'initial' }}>{value.toUpperCase()}</Tag> : null
  }, {
    Header: 'Pack Status',
    id: 'currentPack.isConnected',
    accessor: ({ currentPack }) => {
      return currentPack ? (currentPack.isConnected ? 'connected' : 'disconnected') : 'unknown'
    },
    Cell: ({ value }) => value ? <Tag intent={getStatusIntent(value)} round style={{ borderCollapse: 'initial' }}>{value.toUpperCase()}</Tag> : null,
    minWidth: 110
  }, {
    Header: '# of Packs',
    accessor: 'nPacks',
    minWidth: 80
  }]

  if(role === 'admin') {
    columns = columns.concat([
      {
        Header: 'Pack',
        id: 'currentPack.packId',
        accessor: ({ currentPack }) => (currentPack && currentPack.packId),
        minWidth: 120,
        filterMethod: filterBy('packId'),
        sortMethod: (a, b) => {
          if (!b) return 1
          if (!a) return -1
          return a.packId.toUpperCase() > b.packId.toUpperCase() ? 1 : -1
        }
      }, {
        Header: 'Salesforce ID',
        id: 'salesforceId',
        accessor: ({ salesforceId }) => salesforceId
      }, {
        Header: 'Gateway',
        id: 'gateway',
        accessor: ({ gateway, gatewayId }) => (gatewayId ? gateway || { gatewayId } : null),
        Cell: ({ value }) => value && <GatewayDetailsPopover gateway={value}>{value.gatewayId}</GatewayDetailsPopover>,
        minWidth: 120,
        filterMethod: filterBy('gatewayId'),
        sortMethod: sortBy('gatewayId')
      }
    ])
  }

  return (
    <ReactTable
      defaultSortMethod={sortBy()}
      defaultFilterMethod={filterBy()}
      data={patients}
      columns={columns}
      showPageSizeOptions={false}
      defaultPageSize={100}
      minRows={1}
      freezeWhenExpanded
      filterable
      className='-striped -highlight'
    />
  )
}

export default PatientsTable

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, Card, Elevation, H4, Text, Intent } from '@blueprintjs/core'
import organizationsService from '../../api-services/organizations-service'
import { useList } from '../custom-hooks/api-hooks'
import DashboardElement from '../layout/dashboard-element/dashboard-element'
import { serverErrorToast, successToast } from '../layout/toaster/toaster'
import FormElement from '../commons/form-group/form-group'
import css from './organizations.module.css'

const organizationToLabel = o => { return { value: o._id, label: o.name } }

function OrganizationForm ({ organization }) {
  const history = useHistory()
  const [organizations, setOrganizations] = useState()
  useList(organizationsService, setOrganizations)

  const { register, control, handleSubmit, errors } = useForm()

  if (!organizations) return <Text>Loading...</Text>

  const onSubmit = async ({ name, type, parent, contactName, email, phone, address, city, state, country, zip, timezone, salesforceId, createUser }) => {
    const formData = {
      name,
      salesforceId,
      status: 'active',
      type,
      timezone: timezone ? timezone.value : null,
      parentId: parent ? parent.value : null,
      contact: { contactName, email, phone, address, city, state, country, zip },
      createUser
    }

    try {
      const { _id, createUser: userData } = organization ? await organizationsService.patch(organization._id, formData) : await organizationsService.post(formData)
      if (createUser) {
        successToast(`Organization and user were successfully created. User login email: ${userData.email} and password: ${userData.password}`, null)
      } else { successToast('Organization was successfully saved') }

      history.push(`/organizations/${_id}`)
    } catch (err) {
      serverErrorToast(err)
    }
  }

  const data = organization ? {
    ...organization.contact,
    name: organization.name,
    salesforceId: organization.salesforceId,
    timezone: organization.timezone ? { label: organization.timezone, value: organization.timezone } : null,
    type: organization.type,
    parent: organization.parentId ? organizationToLabel(organizations.find(o => o._id === organization.parentId)) : null
  } : { type: null, parent: null }

  return (
    <DashboardElement>
      <H4>{organization ? `Edit Organization: ${organization.name}` : 'Add Organization'}</H4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={css.row}>
          <div className={css.col} style={{ marginRight: '15px' }}>
            <Card interactive elevation={Elevation.TWO}>
              <h5>Basic Info</h5>
              <FormElement name='name' inputRef={register({ required: true })} label='Name' defaultValue={data.name} errors={errors} />
              <FormElement name='type' inputRef={register({ required: true })} label='Type' errors={errors} defaultValue={data.type} />
              <FormElement name='parent' control={control} isClearable label='Parent Organizations' options={organizations.map(o => organizationToLabel(o))} errors={errors} defaultValue={data.parent} />
              <FormElement name='salesforceId' label='Salesforce ID' inputRef={register} errors={errors} defaultValue={data.salesforceId} />
              <Button intent={Intent.SUCCESS} type='submit'>Save</Button>
            </Card>
          </div>
          <div className={css.col}>
            <Card interactive elevation={Elevation.TWO}>
              <h5>Contact Details</h5>
              <FormElement label='Contact Name' inputRef={register} name='contactName' errors={errors} defaultValue={data.contactName} />
              <FormElement label='E-mail' inputRef={register({ pattern: /\S+@\S+\.\S+/ })} name='email' errors={errors} defaultValue={data.email} />
              <FormElement label='Phone Number' labelInfo='+1 (XXX) XXX-XXXX' inputRef={register({ pattern: /\+1[ ]?\(?[0-9]{3}\)?[ ]?[0-9]{3}-?[0-9]{4}/i })} name='phone' errors={errors} defaultValue={data.phone} />
              <FormElement label='Street Address' inputRef={register} name='address' errors={errors} defaultValue={data.address} />
              <FormElement label='City' inputRef={register} name='city' errors={errors} defaultValue={data.city} />
              <FormElement label='State/Province' inputRef={register} name='state' errors={errors} defaultValue={data.state} />
              <FormElement label='Country' inputRef={register} name='country' errors={errors} defaultValue={data.country} />
              <FormElement label='ZIP' inputRef={register} name='zip' errors={errors} defaultValue={data.zip} />
              <Button intent={Intent.SUCCESS} type='submit'>Save</Button>
            </Card>
          </div>
        </div>
      </form>
    </DashboardElement>
  )
}

export default OrganizationForm

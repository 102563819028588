import React, { useState, useEffect } from 'react'
import { Button, H4, H5, Popover } from '@blueprintjs/core'
import ReactTable from 'react-table'
import { sortBy, filterBy } from '../../../utils/tables'
import alertsService from '../../../api-services/patient-alerts-service'
import { serverErrorToast } from '../../layout/toaster/toaster'
import DashboardElement from '../../layout/dashboard-element/dashboard-element'
import css from './patient.module.css'

const getAlertName = (type) => type.replace('-', ' ').toUpperCase()

const DataBlock = (data) => {
  if (!data) return []

  const blocks = []
  for (const [key, value] of Object.entries(data)) {
    const print = typeof value === 'object' ? DataBlock(value) : value.toString()
    blocks.push(<div key={key}><b>{key}</b>: {print} </div>)
  }
  return blocks
}

const DataPopover = ({ data, children }) => <Popover content={<div style={{ padding: 10 }}>{Object.entries(data).map(([key, value]) => {
  return <div key={key} style={{ marginBottom: 10 }}>
    <H5>{key}</H5>
    {typeof value === 'object' ? DataBlock(value) : value.toString()}
  </div>
})}</div>} target={children} />

const Alerts = ({ patient }) => {
  const [alerts, setAlerts] = useState(null)

  useEffect(() => {
    let isMounted = true
    const fetchAlerts = async () => {
      try {
        const al = await alertsService.fetchPerPatient(patient._id)
        if (isMounted) setAlerts(al)
      } catch (err) {
        serverErrorToast(err)
      }
    }
    fetchAlerts()
    return () => { isMounted = false }
  }, [patient._id])

  if (!alerts) return null

  const columns = [{
    Header: 'Timestamp (UTC)',
    accessor: 'messageTime',
    minWidth: 180
  }, {
    Header: 'Alert',
    id: 'type',
    accessor: ({ type }) => (getAlertName(type))
  }, {
    Header: 'Pack ID',
    id: 'packId',
    accessor: ({ data }) => data.packId
  }, {
    Header: 'Details',
    id: 'data',
    accessor: ({ data }) => <DataPopover data={data}><Button icon='more' minimal /></DataPopover>
  }]

  return (
    <DashboardElement className={css.personalInfo}>
      <H4>Patient Alerts</H4>
      <ReactTable
        defaultSortMethod={sortBy()}
        defaultFilterMethod={filterBy()}
        data={alerts}
        columns={columns}
        showPageSizeOptions={false}
        defaultPageSize={100}
        minRows={1}
        freezeWhenExpanded
        filterable
        className='-striped -highlight'
      />
    </DashboardElement>
  )
}

export default React.memo(Alerts)

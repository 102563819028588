import React from 'react'
import { Intent, Tag } from '@blueprintjs/core'
import DashboardElement from '../../layout/dashboard-element/dashboard-element'
import DetailsBox from '../../commons/details-box/details-box'
import css from './patient.module.css'
import moment from 'moment'

const getStatusIntent = isConnected => isConnected ? Intent.SUCCESS : Intent.DANGER
const getStatus = isConnected => isConnected ? 'CONNECTED' : 'DISCONNECTED'
const getLastTransmissionTime = lastTransmission => moment.duration(moment().diff(moment(lastTransmission))).humanize()

const Gateway = ({ patient }) => {
  if (!patient.gateway) return 'No gateway associated'

  const { gatewayId, status, lastMessageTime, isConnected } = patient.gateway

  // const [currentVolumeLevel, setCurrentVolumeLevel] = useState(status?.currentVolumeLevel || 0)
  // const [currentScreenBrightness, setCurrentScreenBrightness] = useState(status?.currentScreenBrightness || 0)

  // const submitCommand = async (commands) => {
  //   try {
  //     await gatewaysService.patch(gatewayId, { commands })
  //     successToast(`Command was stored on server`)
  //   } catch (err) {
  //     serverErrorToast(err)
  //   }
  // }

  return (
    <DashboardElement className={css.personalInfo}>
      <DetailsBox title='Gateway Details'>
        <DetailsBox.Item text='Gateway ID' value={gatewayId} striped />
        <DetailsBox.Item text='Status' value={<Tag intent={getStatusIntent(isConnected)} round style={{ borderCollapse: 'initial' }}>{getStatus(isConnected)}</Tag>} striped />
        <DetailsBox.Item text='Plugged In' value={<Tag intent={status?.pluggedIn ? Intent.SUCCESS : Intent.DANGER} round style={{ borderCollapse: 'initial' }}>{status?.pluggedIn ? 'PLUGGED IN' : 'UNPLUGGED'}</Tag>} striped />
        <DetailsBox.Item text='Last Message Time' value={getLastTransmissionTime(lastMessageTime)} striped />
        <DetailsBox.Item text='Battery Level' value={status?.gatewayBatteryLevel || '0'} striped />
        <DetailsBox.Item text='Volume Level' value={status?.currentVolumeLevel || '0'} striped />
        <DetailsBox.Item text='Brightness Level' value={status?.currentScreenBrightness || '0'} striped />
        <DetailsBox.Item text='App Version' value={status?.appVersion} striped />
        <DetailsBox.Item text='Device Timestamp' value={status?.currentTime} striped />
        { /*<DetailsBox.Item text='Volume Level' value={<>
          <div style={{ display: 'inline-block', width: 300, marginRight: 50 }} >
            <Slider min={0} max={100} value={currentVolumeLevel} onChange={value => setCurrentVolumeLevel(value)} labelStepSize={100} />
          </div>
          <Button intent={Intent.PRIMARY} text='Change Level' onClick={() => submitCommand({ currentVolumeLevel })} />
        </>} striped />
        <DetailsBox.Item text='Brightness Level' value={<>
          <div style={{ display: 'inline-block', width: 300, marginRight: 50 }} >
            <Slider min={0} max={100} value={currentScreenBrightness} onChange={value => setCurrentScreenBrightness(value)} labelStepSize={100} />
          </div>
          <Button intent={Intent.PRIMARY} text='Change Level' onClick={() => submitCommand({ currentScreenBrightness })} />
        </>} striped />
        <DetailsBox.Item
          text='Cancel Notification'
          value={<Button intent={Intent.PRIMARY} text='Cancel Notification' onClick={() => submitCommand({ cancelNotification: true })} />}
          striped />
        <DetailsBox.Item text='Reset' value={<Button
          intent={Intent.DANGER}
          text='Reset Gateway'
          onClick={() => submitCommand({ resetGateway: {
            resetRequested: true,
            resetRequestedAt: (+new Date())
          } })} />} striped /> */}
      </DetailsBox>
    </DashboardElement>
  )
}

export default Gateway

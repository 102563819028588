import React from 'react'
import { Row } from 'react-grid-system'
import Skeleton from 'react-loading-skeleton'
import Pack from '../../commons/pack/pack'
import css from './patient.module.css'

import { sortDescending } from '../../../utils/packs'

function renderPacks (patient, packs) {
  const { timezone } = patient
  return sortDescending(packs).map(pack => {
    return (
      <div className={css.packsListItem} key={pack._id} >
        <Pack pack={pack} options={{ timezone }} />
      </div>)
  })
}
function renderPatientPacks ({ patient, packs }) {
  if (!patient || !packs) {
    return (
      <div style={{ fontSize: 20, lineHeight: 2 }}>
        <Skeleton />
        <Skeleton count={5} />
      </div>
    )
  }

  return (
    <div>
      <Row style={{ justifyContent: 'center' }}>
        {renderPacks(patient, packs)}
      </Row>
    </div>
  )
}

export default renderPatientPacks

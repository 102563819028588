import React, { useState } from 'react'
import { Button, Intent } from '@blueprintjs/core'
import { useForm } from 'react-hook-form'
import authenticationServices from '../../authentication-services'

import { useFilePicker } from "use-file-picker";

import axios from 'axios'
import config from '../../config'

function CSVFileSelectorForm ({ setDialogOpen, patient }) {
  const [selectedFile, SetSelectedFile] = useState(null)
  const acceptedFiles = [".json", ".csv", "*.txt"];

  const {  handleSubmit } = useForm({
  defaultValues:  {}
  })

  const onSubmit = async (data) => {
    console.log(`Bearer ${authenticationServices.authenticationData.accessToken}`)
    console.log("baseUrl: " + config.urls.packs)

    const result = await axios.post(config.urls.packs + "/uploadStickerId", selectedFile,
    {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authenticationServices.authenticationData.accessToken}`
        }
    }).then((response) => {
      if (response.status === 200) {
        alert("Sticker mapping uploaded successfully!");
      } else {
        alert("Sticker mappiing upload failed!");
      }
    });
    
    setDialogOpen(null);

    return result
  }

  const [openFileSelector, { loading, errors, filesContent }] = useFilePicker({
  multiple: false,
  accept: acceptedFiles
  });

  if (errors.length) {
    return (
      <div>
        <button onClick={() => openFileSelector()}>
          Something went wrong, retry!
        </button>
        {errors[0].readerError && errors[0].readerError}
      </div>
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleFileSelect = (event) => {
    var x = document.getElementById("inputFile").value;
    var validity: boolean = false;
    acceptedFiles.forEach((element) => {
      //console.log(element);
      if (x.endsWith(element)) {
        validity = true;
      }
    });

    if (validity === true) {
      SetSelectedFile(event.target.files[0])
    } else {
      alert("Please select a *.json file");
    }
    console.log(validity);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
     <div className='bp3-dialog-header'>
        <span className='bp3-icon-large bp3-icon-inbox' />
        <h4 className='bp3-heading'>Import Pack Map CSV File</h4>
        <button onClick={() => setDialogOpen(false)} type='button' aria-label='Close' className='bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross' />
      </div>
      
      <div>
      {filesContent.map((file) => (
        <React.Fragment key={file.name}>{file.name}</React.Fragment>
      ))}
      
      <input type="file" id="inputFile" accept=".json, .csv" onChange={handleFileSelect} />
    </div>
      <div className='bp3-dialog-footer'>
        <div className='bp3-dialog-footer-actions' >
          <div>
            <Button onClick={() => setDialogOpen(false)} type='button'>Cancel</Button>
            <Button intent={Intent.SUCCESS} type='submit' icon='saved' loading={loading} >Ok</Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default CSVFileSelectorForm

import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { Button, H4, Colors, Classes, Intent } from '@blueprintjs/core'
import { ResponsiveContainer, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Line } from 'recharts'
import DashboardElement from '../../layout/dashboard-element/dashboard-element'
import { getActivePatientsHistory } from '../../../utils/packs'
import { syncSalesforce } from '../../../api-services/salesforce-service'
import { serverErrorToast, successToast } from '../../layout/toaster/toaster'
import css from './active-patients-chart.module.css'

const INITIAL_PERIOD = 6

function ActivePatientsChart ({ packs }) {
  const [fullDataSet, setFullDataSet] = useState()
  const [syncingSalesforce, setSyncingSalesforce] = useState(false)
  const [period, setPeriod] = useState(INITIAL_PERIOD)
  const [dataSet, setDataSet] = useState()

  const changePeriod = newPeriod => {
    if (!dataSet || !dataSet.length) return
    setPeriod(newPeriod)
    const startDate = moment().subtract(newPeriod, 'months')
    const newDataSet = fullDataSet && fullDataSet.filter(dataPoint => startDate.isBefore(dataPoint.Date))
    setDataSet(newDataSet)
  }

  useEffect(() => {
    if (!packs) return
    // TODO: this is actually counting active packs instead of active patients, should be fixed
    const history = getActivePatientsHistory(packs, 6, `month`)
    const processedDataSet = Object.keys(history).map(key => ({ 'Date': key, 'Active Packs': history[key] }))
    setFullDataSet(processedDataSet)
  }, [packs])

  useEffect(() => {
    setDataSet(fullDataSet)
    changePeriod(period)
  }, [fullDataSet])

  const sync = async () => {
    try {
      setSyncingSalesforce(true)
      const response = await syncSalesforce()
      successToast(response.message)
    } catch (err) {
      serverErrorToast(err)
    }
    setSyncingSalesforce(false)
  }

  const ChartControlButton = ({ value }) => (
    <Button
      minimal
      intent={Intent.PRIMARY}
      text={`${value} months`}
      active={period === value}
      onClick={() => changePeriod(value)}
      disabled={!dataSet}
    />
  )
  return (
    <DashboardElement className={css.activePatientsChart}>
      <div className={css.header}>
        <H4>Active Patients</H4>
        <div className={css.chartControlButtons}>
          <Button disabled={syncingSalesforce} icon='refresh' intent='danger' onClick={sync}>Sync Salesforce</Button>
          <ChartControlButton value={1} />
          <ChartControlButton value={3} />
          <ChartControlButton value={6} />
        </div>
      </div>
      <div className={classNames(css.chartWrapper, { [Classes.SKELETON]: !dataSet })}>
        <ResponsiveContainer>
          <LineChart data={dataSet} margin={{ top: 5, right: 5, left: -20, bottom: -1 }}>
            <XAxis dataKey='Date' angle={10} dy={5} />
            <YAxis />
            <CartesianGrid stroke='#EBF1F5' />
            <Tooltip />
            <Line type='monotone' dataKey='Active Packs' stroke={Colors.BLUE3} strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </DashboardElement>
  )
}

export default ActivePatientsChart

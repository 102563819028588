import { useState, useEffect } from 'react'
import { Intent } from '@blueprintjs/core'
import authenticationServices from '../../authentication-services'

function AuthenticationManager ({ toaster, isAuthenticated, location, hasPermissions }) {
  const [hasBeenAuthenticated, setHasBeenAuthenticated] = useState(false)
  useEffect(() => { authenticationServices.handleAuthentication(location.hash) }, [])
  useEffect(() => { if (isAuthenticated) authenticationServices.renewSession() }, [location.pathname])
  useEffect(() => {
    if (isAuthenticated && !hasBeenAuthenticated) {
      setHasBeenAuthenticated(true)
    }
    if (!isAuthenticated && hasBeenAuthenticated) {
      toaster.clear()
      setTimeout(() => {
        toaster.show({
          action: { onClick: authenticationServices.login, text: 'LOGIN' },
          intent: Intent.PRIMARY,
          icon: 'error',
          message: 'Your session has expired',
          timeout: 0
        })
      }, 500)
    }
    if (isAuthenticated && !hasPermissions) {
      setTimeout(() => {
        toaster.show({
          action: { onClick: authenticationServices.logout, text: 'LOGOUT' },
          intent: Intent.PRIMARY,
          icon: 'error',
          message: 'Your user is not authorized to use this application. Please write to support@cuepath.io for further assistance.',
          timeout: 0
        })
      }, 500)
    }
  }, [isAuthenticated])

  return null
}

export default AuthenticationManager

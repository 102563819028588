import { useState, useEffect } from 'react'
import authenticationServices from '../../authentication-services'

function useAuthentication () {
  const [authenticationData, setAuthenticationData] = useState({})
  useEffect(() => {
    const subscriptionId = authenticationServices.subscribe(setAuthenticationData)
    return () => authenticationServices.unsubscribe(subscriptionId)
  }, [])
  return authenticationData
}

export { useAuthentication }

import React from 'react'
import { Toaster } from '@blueprintjs/core'
import { useAuthentication } from '../custom-hooks/authentication-hooks'
import AuthenticationManager from './authentication-manager'
import DataManager from './data-manager'
import css from './session-manager.module.css'

const toaster = Toaster.create({ className: css.connectionToaster, canEscapeKeyClear: false })

function SessionManager ({ location }) {
  const authenticationData = useAuthentication()

  const ifLoadData = !location.pathname.includes('/report')

  return (
    <>
      <AuthenticationManager toaster={toaster} location={location} isAuthenticated={authenticationData.isAuthenticated} hasPermissions={authenticationData.hasPermissions} />
      { ifLoadData && <DataManager toaster={toaster} isAuthenticated={authenticationData.isAuthenticated} hasPermissions={authenticationData.hasPermissions} />}
    </>
  )
}

export default SessionManager

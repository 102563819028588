import React from 'react'
import { NavLink } from 'react-router-dom'
import { Classes, Text } from '@blueprintjs/core'
import classNames from 'classnames'
import css from './entities-navigation.module.css'

const EntitiesNavigationItem = ({ entity }) => {
  const className = classNames(css.item, Classes.MENU_ITEM, Classes.POPOVER_DISMISS)
  return (
    <li>
      <NavLink activeClassName={Classes.ACTIVE} className={className} to={entity.href}>
        <Text className={css.primaryText}>{entity.text}</Text>
        <small>{entity.secondaryText}</small>
      </NavLink>
    </li>
  )
}

export default EntitiesNavigationItem

import React from 'react'
import { NavLink } from 'react-router-dom'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'
import css from './main-menu.module.css'

const MainMenuItem = ({ href, text }) => {
  const className = classNames(css.item, Classes.MENU_ITEM, Classes.POPOVER_DISMISS)
  return (
    <li>
      <NavLink activeClassName={Classes.ACTIVE} className={className} to={href}>{text}</NavLink>
    </li>
  )
}

export default MainMenuItem

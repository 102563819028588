export default {
  organizations: '/organizations/organizations',
  salesforce: '/organizations/salesforce',
  caregivers: '/organizations/caregivers',
  users: '/organizations/users',
  gateways: '/devices/gateways/',
  gatewayEvents: '/devices/gateways/events',
  packs: '/packs/packs',
  patients: '/patients/patients',
  patientAlerts: '/patients/alerts'
}

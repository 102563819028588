import React from 'react'
import classNames from 'classnames'
import { Classes } from '@blueprintjs/core'
import css from './side-bar.module.css'

function MainMenu ({ menu, secondary, elevation, children }) {
  return (
    <div className={classNames(css.sideBar, Classes[`ELEVATION_${elevation}`])}>
      {children}
    </div>
  )
}

export default MainMenu

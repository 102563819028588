/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import FlexRow from '../layout/flex-row/flex-row'
import FlexColumn from '../layout/flex-column/flex-column'
import ActivePatientsChart from './active-patients-chart/active-patients-chart'
import ActivePatientsTable from './active-patients-table/active-patients-table'
import MonitoredPatientsChart from './monitored-patients-chart/monitored-patients-chart'
import organizationsService from '../../api-services/organizations-service'
import patientsService from '../../api-services/patients-service'
import packsService from '../../api-services/packs-service'
import gatewaysService from '../../api-services/gateways-service'
import { useList } from '../custom-hooks/api-hooks'

import css from './dashboard.module.css'

function Dashboard () {
  const [patients, setPatients] = useState()
  const [packs, setPacks] = useState()
  const [organizations, setOrganizations] = useState()
  const [gateways, setGateways] = useState() // it sounds a little strange, but I think is ok for now, I don't want to stay thinking about it too much at this point

  useList(patientsService, setPatients)
  useList(packsService, setPacks)
  useList(organizationsService, setOrganizations)
  useList(gatewaysService, setGateways)

  const activePatients = patients && patients.filter(patient => patient.activeStatus === 'active')

  return (
    <div className={css.dashboard}>
      <FlexRow className={css.firstRow}>
        <ActivePatientsChart packs={packs} />
        <FlexColumn className={css.secondColumn}>
          <MonitoredPatientsChart activePatients={activePatients} />
        </FlexColumn>
      </FlexRow>
      <FlexRow className={css.secondRow}>
        <ActivePatientsTable activePatients={activePatients} />
      </FlexRow>
    </div>
  )
}

export default Dashboard

import config from '../config'
import GenericApiService from './generic-api-service'

const baseURL = config.urls.gateways

const populateGateways = (gateways, patientsService) => {
  gateways.forEach(g => {
    const patients = patientsService.filterWithIndex('gatewayId', g.gatewayId)
    g.patient = patients.length ? patients[0] : null
  })
  return gateways.sort((a, b) => a.lastMessageTime > b.lastMessageTime ? -1 : 1)
}

const gatewaysService = new GenericApiService(baseURL, 'gatewayId', [], populateGateways)

export default gatewaysService

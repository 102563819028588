import React, { useEffect, useState } from 'react'
import { Tab, Tabs, H2, Button, Intent, Dialog, ButtonGroup } from '@blueprintjs/core'
import packsService from '../../../api-services/packs-service'
import DetailsBox from '../../commons/details-box/details-box'
import authenticationServices from '../../../authentication-services'
import OverviewTab from './overview'
import PacksTab from './packs'
import PatientForm from '../patient-form'
import CSVFileSelectorForm  from '../../imports/csv-file-selector-form'
import exportCSV  from '../../export/exportCSV'
import Gateway from './gateway'
import Alerts from './alerts'
import css from './patient.module.css';

function Patient ({ patient }) {
  const [packs, setPacks] = useState(null)
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [isDialogFileSelector, setDialogFileSelector] = useState(false)

  const { role } = authenticationServices.authenticationData

  async function fetchPacks () {
    setPacks(null)
    if (!patient.packs) return

    if (!patient.packs.length) return setPacks([])

    const packs = await packsService.fetch({ patientId: patient._id })
    setPacks(packs)
  }

  useEffect(() => {
    fetchPacks()
  }, [patient._id, patient.packs])

  if (!patient.packs || packs === null) {
    return (
      <div className={css.patient}><H2>Loading packs...</H2></div>
    )
  }

  const currentPack = patient.currentPack && packs.find(p => p._id === patient.currentPack._id)

  return (
    <div className={css.patient}>
      <DetailsBox
        title={`${patient.firstName} ${patient.lastName}`}
        primaryActionButton={<ButtonGroup>
          {patient.patientType === 'lab' && <Button  variant='outlined' onClick={() => setDialogFileSelector(true)}>Import PackMap</Button>}
          {patient.patientType === 'lab' && <Button icon='print' onClick={()=>exportCSV(patient)}>Download CSV Report</Button>}
          {role !== 'callcenter' && <Button icon='print' onClick={() => window.open(`/report-patient/${patient._id}`, '_blank')}>Report</Button>}
           {role === 'admin' && <Button intent={Intent.PRIMARY} icon='edit' onClick={() => setDialogOpen(true)}>Edit</Button>}
        </ButtonGroup>}
      />
      <Tabs large id='PatientTabs' defaultSelectedTabId='overview' >
        <Tab id='overview' title='Overview' panel={<OverviewTab patient={patient} currentPack={currentPack} />} />
        <Tab id='packs' title='Packs' panel={<PacksTab patient={patient} packs={packs} />} />
        <Tab id='gateway' title='Gateway' panel={<Gateway patient={patient} />} />
        { role === 'admin' && <Tab id='alerts' title='Alerts' panel={<Alerts patient={patient} />} />}
      </Tabs>
      <Dialog
        isOpen={isDialogOpen}>
        <PatientForm setDialogOpen={setDialogOpen} patient={patient} />
      </Dialog>
      <Dialog
        isOpen={isDialogFileSelector}>
        <CSVFileSelectorForm setDialogOpen={setDialogFileSelector} patient={patient} />
      </Dialog>
    </div>
  )
}

export default React.memo(Patient)

import React from 'react'
import { H3, Divider, Tag } from '@blueprintjs/core'
import FlexRow from '../../layout/flex-row/flex-row'
import DetailsBoxItem from './details-box-item'
import css from './details-box.module.css'

function DetailsBox ({ title, status, statusIntent, children, actionButtons, primaryActionButton }) {
  return (
    <>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <div>
          <H3 className={css.header}>{title}</H3>
          {status ? <Tag intent={statusIntent} className={css.status}>{status}</Tag> : null}
          {actionButtons}
        </div>
        <div>
          {primaryActionButton}
        </div>
      </FlexRow>
      <Divider />
      {children}
    </>
  )
}

DetailsBox.Item = DetailsBoxItem

export default DetailsBox

import React from 'react'
import { Alignment, Button, Classes, Navbar } from '@blueprintjs/core'
import classNames from 'classnames'
import css from './top-bar.module.css'
import logo from '../../../images/cuepath_icon_full_color.png'

function TopBar ({ userName, userAvatar, logoutHandler, role }) {
  return (
    <Navbar className={classNames(css.topBar, Classes.ELEVATION_4)}>
      <Navbar.Group align={Alignment.LEFT}>
        <img src={logo} alt='logo' className={css.logo} />
        <Navbar.Heading className={css.topBarHeading}>{role === 'admin' ?  'CuePath Admin' : 'CueCare'} Portal</Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        {userAvatar ? <img src={userAvatar} alt='avatar' className={css.avatar} /> : null}
        <span className={css.userLabel}>{userName}</span>
        {logoutHandler ? <Button text='Logout' onClick={logoutHandler} /> : null}
      </Navbar.Group>
    </Navbar>
  )
}

export default TopBar

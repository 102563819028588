import React from 'react'
import ReactTable from 'react-table'
import { Card } from '@blueprintjs/core'
import SkeletonTableRows from '../../layout/skeleton-table-rows/skeleton-table-rows'

function GatewayEventsTable ({ gatewayEvents }) {
  if (!gatewayEvents) return <SkeletonTableRows nRows={10} nColumns={8} />
  const columns = [{
    Header: 'Pack ID',
    accessor: 'packId',
    minWidth: 150
  }, {
    Header: 'Gateway ID',
    accessor: 'gatewayId',
    minWidth: 125
  }, {
    Header: 'User ID',
    accessor: 'userId',
    minWidth: 80
  }, {
    Header: 'Timestamp (UTC)',
    accessor: 'createdAt',
    minWidth: 180
  }, {
    Header: 'Binary Cavities States',
    accessor: 'binaryCavitiesStates',
    minWidth: 260
  }, {
    Header: 'Battery',
    accessor: 'packBatteryLevel',
    maxWidth:60
  }, {
    Header: 'Signal',
    accessor: 'packSignalStrength',
    maxWidth:60
  }, {
    Header: 'Events',
    id: 'events',
    minWidth: 320,
    accessor: ({ events }) => <>{events.map(({ time, blisterPackSlot, battery }) => {
      return <Card key={time}>
        { blisterPackSlot ? (<div>Col:<b>{blisterPackSlot[1]}</b> Row:<b>{blisterPackSlot[0]}</b> </div>)
          : <div></div>}
        <div>Time(PST): <b>{(new Date(time)).toDateString()}</b> {(new Date(time)).toLocaleTimeString()} </div>
        <div>Time(UTC): {(new Date(time)).toUTCString()}</div>
        <div>Battery: <b>{battery}</b></div>
      </Card>
    })}</>
  }]

  return (
    <ReactTable
      data={gatewayEvents}
      columns={columns}
      showPageSizeOptions={false}
      defaultPageSize={100}
      minRows={1}
      freezeWhenExpanded
      filterable
      className='-striped -highlight'
    />
  )
}

export default GatewayEventsTable

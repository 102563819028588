import React from 'react'
import { Popover } from '@blueprintjs/core'
import GatewayEventsTable from './gateway-events-table'

function GatewayEventsPopover ({ gatewayEvents, children, onClose, isOpen }) {
  const content =
    <div style={{ padding: '5px 10px 10px 10px' }}>
      <GatewayEventsTable gatewayEvents={gatewayEvents} />
    </div>
  return <Popover boundary='window' modifiers={{ flip: true, preventOverflow: true }} usePortal content={content} target={children} onClose={onClose} isOpen={isOpen} />
}

export default GatewayEventsPopover

import React from 'react'

import TopBar from './top-bar/top-bar'
import MainMenu from './main-menu/main-menu'
import NavigationContainer from './navigation-container/navigation-container'

import css from './layout.module.css'

function Layout ({ children, userName, userAvatar, role, logoutHandler }) {
  return (
    <div className={css.appContainer}>
      <TopBar userName={userName} userAvatar={userAvatar} logoutHandler={logoutHandler} role={role} />
      { role !== 'callcenter'
        ? <NavigationContainer menu={<MainMenu role={role} />} menuElevation='2'>
          {children}
        </NavigationContainer>
        : children }
    </div>
  )
}

export default Layout

import React from 'react'
import { Menu, Classes, H6 } from '@blueprintjs/core'
import EntitiesNavigationItem from './entities-navigation-item'
import css from './entities-navigation.module.css'

function EntitiesNavigation ({ entities, title }) {
  const items = entities && entities.map(entity => <EntitiesNavigationItem entity={entity} key={entity.href} />)
  return (
    <Menu large className={css.menu}>
      <li><H6 className={Classes.MENU_HEADER} style={{ textTransform: 'capitalize' }}>{title}</H6></li>
      <Menu.Divider />
      {items}
    </Menu>
  )
}

export default EntitiesNavigation

import React, { useState } from 'react'
import patientsService from '../../api-services/patients-service'
import { useList, useOne } from '../custom-hooks/api-hooks'
import NavigationContainer from '../layout/navigation-container/navigation-container'
import EntitiesNavigation from '../commons/entities-navigation/entities-navigation'
import SkeletonTableRows from '../layout/skeleton-table-rows/skeleton-table-rows'
import NotFound from '../layout/not-found/not-found'
import authenticationServices from '../../authentication-services'
import Patient from './patient/patient'

const DEFAULT_PATIENT = {}

function PatientNavigationWrapper ({ match }) {
  const { id } = match.params
  const { role } = authenticationServices.authenticationData
  const [patients, setPatients] = useState()
  const [patient, setPatient] = useState(DEFAULT_PATIENT)
  useList(patientsService, setPatients)
  useOne(patientsService, id, setPatient)

  if (patient === DEFAULT_PATIENT) return <SkeletonTableRows nRows={1} nColumns={1} />
  if (!patient) return <NotFound />

  const entities = patients && patients.map(patient => ({
    text: `${patient.medId} ${patient.firstName} ${patient.lastName}`,
    secondaryText: `# of packs: ${patient.nPacks} / last: ${patient.activeStatus}`,
    href: `/patients/${patient._id}`
  }))

  return role === 'admin' ? (
    <NavigationContainer menu={<EntitiesNavigation entities={entities} title='Patients' />} menuElevation='0'>
      <Patient patient={patient} />
    </NavigationContainer>
  ) : <Patient patient={patient} />
}

export default PatientNavigationWrapper

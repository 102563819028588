import urls from './urls'

const getConfig = () => {
  let mainUrl = 'https://cuepathdev.azure-api.net'
  // eslint-disable-next-line default-case
  switch (process.env.REACT_APP_ENV) {
    case 'production-us':
      mainUrl = 'https://cpi-us.azure-api.net'
      break
    case 'production-canada':
      mainUrl = 'https://cpi-canada.azure-api.net'
      break
  }

  Object.keys(urls).forEach(key => {
    urls[key] = mainUrl + urls[key]
  })

  return { urls }
}

export default getConfig()

import moment from 'moment'
import { Intent } from '@blueprintjs/core'

export function findCurrentPack (packs) {
  const pack = packs.reverse().find(pack => moment().isBetween(pack.startDate, pack.endDate))
  return pack
}

export function findActivePackByDate (packs, date) {
  const pack = packs.find(pack => moment(date).isBetween(pack.startDate, pack.endDate))
  return pack
}

export function findNextPreparedPack (packs) {
  const mapped = packs.map(function (pack, i) {
    return { index: i, pack }
  })

  // sorting the mapped array containing the reduced values
  mapped.sort((a, b) => moment(a.pack.startDate).isAfter(moment(b.pack.startDate)))

  let nextPreparedPackMappedIndex = mapped.findIndex(element => moment().isAfter(element.pack.startDate)) - 1
  if (nextPreparedPackMappedIndex < 0) return null

  const nextPreparedPackIndex = mapped[nextPreparedPackMappedIndex].index

  return packs[nextPreparedPackIndex]
}

export function findLastFinishedPack (packs) {
  const mapped = packs.map(function (pack, i) {
    return { index: i, pack }
  })

  // sorting the mapped array containing the reduced values
  mapped.sort((a, b) => moment(a.pack.startDate).isBefore(moment(b.pack.startDate)) ? 1 : -1)

  let lastFinishedPackMappedIndex = mapped.findIndex(element => moment().isAfter(element.pack.endDate))
  if (lastFinishedPackMappedIndex < 0) return null

  const lastFinishedPackIndex = mapped[lastFinishedPackMappedIndex].index

  return packs[lastFinishedPackIndex]
}

export function getLastActivePackStatus (packs) {
  if (!packs || !packs.length) return 'none'
  if (findCurrentPack(packs)) return 'active'
  const finishedPack = findLastFinishedPack(packs)
  if (!finishedPack) return 'new'

  const years = moment().diff(finishedPack.endDate, 'years')
  if (years) return `${years} ${years > 1 ? 'years' : 'year'}`

  const months = moment().diff(finishedPack.endDate, 'months')
  if (months) return `${months} ${months > 1 ? 'months' : 'month'}`

  const weeks = moment().diff(finishedPack.endDate, 'weeks')
  if (weeks) return `${weeks} ${weeks > 1 ? 'weeks' : 'week'}`

  const days = moment().diff(finishedPack.endDate, 'days')
  return `${days} ${days > 1 ? 'days' : 'day'}`
}

export function getActivePatientsHistory (packs, time, timeUnit) {
  const end = moment().startOf('day')
  const start = moment(end).subtract(time, timeUnit)
  const history = {}

  for (let date = moment(start); date.isBefore(end); date.add(1, 'day')) {
    history[date.format('YYYY-MM-DD')] = 0
  }

  // TODO: this is actually counting active packs instead of active patients, should be fixed
  function fillPackHistory (pack) {
    for (let date = moment(pack.startDate); date.isSameOrBefore(pack.endDate); date.add(1, 'day')) {
      if (history[date.format('YYYY-MM-DD')] || history[date.format('YYYY-MM-DD')] === 0) history[date.format('YYYY-MM-DD')]++
    }
  }

  packs.filter(pack => start.isSameOrBefore(pack.endDate) && end.isSameOrAfter(pack.startDate)).forEach(fillPackHistory)

  return history
}

export function sortDescending (packs) {
  const copied = [...packs]

  // sorting the mapped array containing the reduced values
  copied.sort((a, b) => moment(a.startDate).isBefore(moment(b.startDate)) ? 1 : -1)

  return copied
}

export function getIntentByScore (score, target) {
  if(!target) target = 80
  if(!score) return null
  if (score > target) return Intent.SUCCESS
  if (score < (target - (100 - target))) return Intent.DANGER
  return Intent.WARNING
}

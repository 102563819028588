import React from 'react'
import { Menu } from '@blueprintjs/core'
import MainMenuItem from './main-menu-item'
import css from './main-menu.module.css'

function MainMenu ({ role, menu, secondary }) {
  return (
    <Menu large className={css.menu}>
      {role === 'admin' && <MainMenuItem href='/dashboard' text={'Dashboard'} active />}
      <MainMenuItem href='/organizations' text='Organizations' />
      <MainMenuItem href='/patients' text='Patients' />
      {role === 'admin' && <MainMenuItem href='/gateways' text='Gateways' />}
    </Menu>
  )
}

export default MainMenu

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import AdherenceHeatmap from '../commons/statistics/charts/adherence-heatmap'
import { findLastFinishedPack } from '../../utils/packs'
import patientsService from '../../api-services/patients-service'
import packsService from '../../api-services/packs-service'
import { getPatientReport } from '../../api-services/salesforce-service'
import DetailsBox from '../commons/details-box/details-box'
import Pack from '../commons/pack/pack'
import { serverErrorToast, errorToast } from '../layout/toaster/toaster'
import css from './reports.module.css'


function Report ({ match }) {
  const { id } = match.params
  const [patient, setPatient] = useState(null)

  async function fetchData () {
    try {
      const patient = await patientsService.fetchOneById(id)
      patient.packs = await packsService.fetch({ patientId: patient._id })
      
      setPatient(patient)
     // window.print()
    } catch (err) {
      serverErrorToast(err)
    }
  }

  useEffect(() => {
    fetchData()
  }, [id])

  if (!patient) return <h1>Loading...</h1>

  const name = `${patient.firstName} ${patient.lastName}`

  return (
    <div className={css.report}>
      <h1>CuePath Mapped Report for {name}</h1>
     
    </div>
  )
}

export default Report

import React from 'react'

import FlexRow from '../flex-row/flex-row'
import SideBar from '../side-bar/side-bar'
import PageView from '../page-view/page-view'

function NavigationContainer ({ menu, children, menuElevation }) {
  return (
    <FlexRow style={{ height: '100%' }}>
      <SideBar elevation={menuElevation}>
        {menu}
      </SideBar>
      <PageView>
        {children}
      </PageView>
    </FlexRow>
  )
}

export default NavigationContainer

import axios from 'axios'
import uuid from 'uuid/v1'
import authenticationServices from '../authentication-services'

const createIndex = (array, key) => array.reduce((index, item, i) => ({ ...index, [item[key]]: i }), {})
const createNonUniqueIndex = (array, key) => array.reduce((index, item, i) => {
  if (Array.isArray(item[key])) {
    item[key].forEach(val => {
      index[val] = index[val] ? [...index[val], i] : [i]
    })
    return index
  }
  return {
    ...index,
    [item[key]]: index[item[key]] ? [...index[item[key]], i] : [i]
  }
}, {})
const objForEach = (obj, fn) => Object.keys(obj).forEach(key => fn(obj[key], key))

function GenericApiService (baseURL, primaryKey = '_id', secondaryIndicesKeys, populateFunction) {
  const api = axios.create({
    baseURL,
    timeout: 40000,
    headers: {
      'Content-Type': 'application/json'
    }
  })
  api.interceptors.request.use(request => {
    request.headers.Authorization = `Bearer ${authenticationServices.authenticationData.accessToken}`
    return request
  })

  this.data = []
  this.index = {}
  this.primaryKey = primaryKey
  this.secondaryIndices = {}
  this.secondaryIndicesKeys = secondaryIndicesKeys || []
  this.subscriptions = {}
  this.dependencies = []

  this.init = async () => {
    this.data = await this.fetch()
    reIndex()
    this.updateSubscriptions()
    return this.data
  }

  const reIndex = () => {
    this.index = createIndex(this.data, primaryKey)
    this.secondaryIndices = {}
    this.secondaryIndicesKeys.forEach(key => { this.secondaryIndices[key] = createNonUniqueIndex(this.data, key) })
  }

  this.populate = (...dependencies) => {
    if (!populateFunction) return
    if (this.dependencies.length === 0) this.dependencies = [...dependencies]
    this.data = populateFunction(this.data, ...this.dependencies)
    reIndex()
    this.updateSubscriptions()
  }

  this.clear = () => {
    this.data = []
    this.index = {}
    this.secondaryIndices = {}
    this.updateSubscriptions()
  }

  this.fetch = async (params = {}) => {
    const response = await api.get('', { params })
    return response.data
  }

  this.fetchOneById = async (id) => {
    const response = await api.get(id)
    return response.data
  }

  this.patch = async (id, body) => {
    const response = await api.patch(id, body)
    this.updateOne(response.data)
    return response.data
  }

  this.put = async (id, body) => {
    const response = await api.put(id, body)
    this.updateOne(response.data)
    return response.data
  }

  this.post = async (body) => {
    const response = await api.post('', body)
    this.updateOne(response.data)
    return response.data
  }

  this.delete = async (id) => {
    const headers = {
      Authorization: `Bearer ${authenticationServices.authenticationData.accessToken}`
    }
    await api.delete(id, { headers })
    this.deleteOne(id)
    return true
  }

  this.findOneById = (id) => {
    const i = this.index[id]
    return this.data[i] || null
  }

  this.filterWithIndex = (indexKey, indexValue) => {
    if (!this.secondaryIndices[indexKey][indexValue]) return []
    return this.secondaryIndices[indexKey][indexValue].map(i => this.data[i])
  }

  this.subscribe = (callback) => {
    const subscriptionId = uuid()
    this.subscriptions[subscriptionId] = callback
    return subscriptionId
  }

  this.unsubscribe = (subscriptionId) => {
    delete this.subscriptions[subscriptionId]
  }

  this.updateSubscriptions = () => {
    objForEach(this.subscriptions, sub => sub && sub([ ...this.data ]))
  }

  this.updateOne = (updateData) => {
    if (!updateData) return

    const i = this.index[updateData[this.primaryKey]]
    if (!i && i !== 0) this.data.push(updateData)
    else objForEach(updateData, (value, key) => { this.data[i][key] = value })

    reIndex()
    this.populate()
  }

  this.deleteOne = (id) => {
    const i = this.index[id]
    if (i >= 0) {
      this.data.splice(i, 1)
    }
    reIndex()
    this.updateSubscriptions()
  }
}

export default GenericApiService

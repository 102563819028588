import React, { useState } from 'react'
import moment from 'moment'
import { Tag, Intent, Button } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import SkeletonTableRows from '../../layout/skeleton-table-rows/skeleton-table-rows'
import ReactTable from 'react-table'
import { sortBy, filterBy } from '../../../utils/tables'
import GatewayEventsPopover from '../../commons/gateway-events/gateway-events-popover'
import gatewayEventsService from '../../../api-services/gateway-events-service'

const getStatusIntent = status => {
  switch (status) {
    case 'connected': return Intent.SUCCESS
    default: return Intent.DANGER
  }
}
const getStatus = (isConnected) => {
  if (!isConnected) return 'disconnected'
  return 'connected'
}
const getLastTransmissionTime = lastTransmission => {
  return moment.duration(moment().diff(moment(lastTransmission))).humanize()
}

function GatewaysTable ({ gateways }) {
  const [gatewayEvents, setGatewayEvents] = useState(null)
  const [selectedGatewayId, setSelectedGatewayId] = useState(null)

  const clearGatewayEvents = async () => {
    setSelectedGatewayId(null)
    setGatewayEvents(null)
  }
  const fetchGatewayEvents = async gatewayId => {
    setSelectedGatewayId(gatewayId)
    const events = await gatewayEventsService.fetch({ gatewayId })
    setGatewayEvents(events)
  }

  if (!gateways) return <SkeletonTableRows nRows={1} nColumns={9} />
  const columns = [{
    Header: 'GatewayId',
    accessor: 'gatewayId',
    minWidth: 120
  }, {
    Header: 'Patient',
    accessor: 'patient',
    Cell: ({ value }) => value ? <Link to={`/patients/${value._id}`}>{`${value.firstName} ${value.lastName}`}</Link> : <Tag intent={Intent.DANGER} round style={{ borderCollapse: 'initial' }}>NOT ASSIGNED</Tag>
  }, {
    Header: 'Battery',
    accessor: 'status.gatewayBatteryLevel'
  }, {
    Header: 'Last Message Time',
    id: 'lastMessageTime',
    accessor: ({ lastMessageTime }) => lastMessageTime ? getLastTransmissionTime(lastMessageTime) : 'N/A'
  }, {
    Header: 'Status',
    id: 'status',
    accessor: ({ isConnected }) => getStatus(isConnected),
    Cell: ({ value }) => <Tag intent={getStatusIntent(value)} round style={{ borderCollapse: 'initial' }}>{value.toUpperCase()}</Tag>,
    minWidth: 110
  }, {
    Header: 'Events History',
    id: 'gatewayEvents',
    accessor: ({ gatewayId }) => gatewayId,
    Cell: ({ value }) => (
      <GatewayEventsPopover gatewayEvents={gatewayEvents} onClose={clearGatewayEvents} isOpen={selectedGatewayId === value}>
        <Button key='gateway-events-popup' icon='heat-grid' minimal onClick={() => fetchGatewayEvents(value)} />
      </GatewayEventsPopover>
    ),
    sortable: false,
    minWidth: 50
  }]
  return (
    <ReactTable
      data={gateways}
      columns={columns}
      defaultSortMethod={sortBy()}
      defaultFilterMethod={filterBy()}
      showPageSizeOptions={false}
      defaultPageSize={100}
      minRows={1}
      freezeWhenExpanded
      filterable
      className='-striped -highlight'
    />
  )
}

export default GatewaysTable

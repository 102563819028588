import React from 'react'
import classNames from 'classnames'
import css from './dashboard-element.module.css'

function DashboardElement ({ className, style, children }) {
  return (
    <div className={classNames(css.dashboardElement, className)} style={style}>
      {children}
    </div>
  )
}

export default DashboardElement

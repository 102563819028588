import { Toaster, Intent } from '@blueprintjs/core'

const toaster = Toaster.create({ position: 'top-right' })

const serverErrorToast = (err) => {
  if (!err.response) throw (err)

  let message = ''
  if (err.response.data) {
    if (err.response.data.message) { message = err.response.data.message } else if (err.response.data.error) { message = err.response.data.error.message }
    console.error(err.response)
  }

  if (err.response.status) { message += ` Status code: ${err.response.status}` }

  toaster.show({
    intent: Intent.DANGER,
    icon: 'error',
    message: `Server error occured: ${message}`,
    timeout: 0
  })
}

const successToast = (message, timeout = 8000) => {
  toaster.show({
    intent: Intent.SUCCESS,
    message,
    timeout
  })
}

const errorToast = (message, timeout = 8000) => {
  toaster.show({
    intent: Intent.DANGER,
    icon: 'error',
    message,
    timeout
  })
}

export { toaster as default, serverErrorToast, successToast, errorToast }

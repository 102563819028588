import config from '../config'
import GenericApiService from './generic-api-service'
import { getLastActivePackStatus, findCurrentPack, findLastFinishedPack } from '../utils/packs'

const baseURL = config.urls.patients
const secondaryIndicesKeys = ['medId', 'pharmacyId', 'hcaId', 'gatewayId']

const populatePatients = (patients, organizationsService, packsService, gatewaysService, caregiversService) => {
  const caregiversByPatientId = caregiversService.data.reduce((index, caregiver) => {
    caregiver.patients.forEach(({ id }) => {
      index[id] = index[id] ? [...index[id], caregiver] : [caregiver]
    })
    return index
  }, {})

  patients.forEach(patient => {
    patient.pharmacy = organizationsService.findOneById(patient.pharmacyId)
    patient.hca = organizationsService.findOneById(patient.hcaId)
    patient.caregivers = caregiversByPatientId[patient._id]
    patient.packs = packsService.filterWithIndex('patientId', patient._id)
    patient.currentPack = findCurrentPack(patient.packs) || findLastFinishedPack(patient.packs)
    patient.nPacks = patient.packs.length
    patient.activeStatus = getLastActivePackStatus(patient.packs)
    patient.gateway = patient.gatewayId ? gatewaysService.findOneById(patient.gatewayId) : null
  })
  return patients.reverse()
}

const patientsService = new GenericApiService(baseURL, '_id', secondaryIndicesKeys, populatePatients)

export default patientsService

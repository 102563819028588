import React, { useState } from 'react'
import { Text, Intent, Tag, Button, H3, Dialog } from '@blueprintjs/core'
import FlexRow from '../../layout/flex-row/flex-row'
import FlexColumn from '../../layout/flex-column/flex-column'
import DashboardElement from '../../layout/dashboard-element/dashboard-element'
import { getIntentByScore } from '../../../utils/packs'
import Pack from '../../commons/pack/pack'
import DetailsBox from '../../commons/details-box/details-box'
import GatewayDetailsPopover from '../../commons/gateway-details-popover/gateway-details-popover'
import authenticationServices from '../../../authentication-services'
import css from './patient.module.css'
import CaregiverForm from '../caregiver-form'

function packTypeLabel (type, description) {
  // if this patient was assigned the pack type dynamally, then use description
  if (description) {
    return description
  }

  // We can try to work out the pack type if doesn't exist.  Support backward compatibility
  switch (type) {
    case 'thrifty28days': return 'Old - Thrifty White 28 Day'
    case 'jones7days': return 'Old - Jones Qube-It 7 Day'
    default: return 'Not Assigned Correctly'
  }
}

function PatientPack ({ patient, currentPack }) {
  if (!currentPack) {
    return (
      <div>
        <Text>No pack to show</Text>
      </div>
    )
  }
  return <Pack pack={currentPack} options={{ timezone: patient.timezone }} />
}

function Overview ({ patient, currentPack }) {
  const [isCaregiverDialogOpen, setCaregiverDialogOpen] = useState(false)
  const [currentCaregiver, setCurrentCaregiver] = useState()
  const { role } = authenticationServices.authenticationData

  if (!patient) return null
  const status = !patient.gateway ? 'UNKNOWN' : patient.gateway.isConnected ? 'CONNECTED' : 'DISCONNECTED'
  const gatewayCell = (
    <GatewayDetailsPopover gateway={patient.gateway || { gatewayId: patient.gatewayId }}>
      <Text>{patient.gatewayId}</Text>
      <Tag intent={patient.gateway && patient.gateway.isConnected ? Intent.SUCCESS : Intent.DANGER} round>{status}</Tag>
    </GatewayDetailsPopover>
  )

  return (
    <FlexRow>
      <FlexColumn className={css.infoColumn}>
        <DashboardElement className={css.personalInfo}>
          <DetailsBox title='Personal Information'>
            <DetailsBox.Item text='Client Id' value={patient.medId} striped />
            <DetailsBox.Item text={'Pharmacy ID'} value={patient.pharmacy?.shortId} striped />
            <DetailsBox.Item text={'7 Days Score'} value={patient.salesforceObject && <Tag intent={getIntentByScore(patient.salesforceObject?.X7_Days_Adherence_Score__c,  patient.salesforceObject?.Adherence_Target__c)} round>{patient.salesforceObject?.X7_Days_Adherence_Score__c}</Tag>} striped />
            <DetailsBox.Item text={'30 Days Score'} value={patient.salesforceObject && <Tag intent={getIntentByScore(patient.salesforceObject?.X30_Days_Adherence_Score__c,  patient.salesforceObject?.Adherence_Target__c)} round>{patient.salesforceObject?.X30_Days_Adherence_Score__c}</Tag>} striped />
            { role !== 'pharmacy' && <DetailsBox.Item text='Salesforce ID' value={patient.salesforceId} striped />}
            <DetailsBox.Item text={'Gateway'} value={gatewayCell} striped />
            { role === 'admin' && <DetailsBox.Item text={'Pharmacy'}
              value={patient.pharmacy && patient.pharmacy.name}
              href={patient.pharmacy && `/organizations/${patient.pharmacy._id}`}
              striped
            />}
            { role === 'admin' && <DetailsBox.Item text={'HCA'}
              value={patient.hca && patient.hca.name}
              href={patient.hca && `/organizations/${patient.hca._id}`}
              striped
            />}
            <DetailsBox.Item text='Pack Type' value={packTypeLabel(patient.packType, patient.packTypeDescription)} striped />
            <DetailsBox.Item text={'Email'} value={patient.email} striped />
            <DetailsBox.Item text={'Phone'} value={patient.phone} striped />
            <DetailsBox.Item text={'Date Of Birth'} value={patient.dateOfBirth} striped />
            <DetailsBox.Item text='Timezone' value={patient.timezone} striped />
            <DetailsBox.Item text={'Number of Packs'} value={patient.nPacks} striped />
            <DetailsBox.Item text={'Last Pack'} value={patient.activeStatus} striped />
            <DetailsBox.Item text='Adherence Threshold' value={patient.adherenceScoreCaseThreshold} striped />
            <DetailsBox.Item text={<>Prescription<br/>Start Date</>} value={patient.subscriptionStartDate} striped />
          </DetailsBox>
          <H3 style={{ marginTop: 20 }}>Caregivers</H3>
          {patient.caregivers && patient.caregivers.map(c => <p key={c._id}>{c.firstName} {c.lastName} ({c.email}) <Button icon='edit' minimal onClick={() => {
            setCaregiverDialogOpen(true)
            setCurrentCaregiver(c)
          }} /></p>)}
          { role === 'admin' && <Button intent={Intent.PRIMARY} icon='plus' text='Invite Caregiver' onClick={() => {
            setCaregiverDialogOpen(true)
            setCurrentCaregiver(null)
          }} />}
        </DashboardElement>
      </FlexColumn>
      <FlexColumn className={css.packColumn}>
        <PatientPack patient={patient} currentPack={currentPack} />
      </FlexColumn>
      <Dialog
        isOpen={isCaregiverDialogOpen}>
        <CaregiverForm setDialogOpen={setCaregiverDialogOpen} caregiver={currentCaregiver} patient={patient} />
      </Dialog>
    </FlexRow>
  )
}

export default Overview

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import AdherenceHeatmap from '../commons/statistics/charts/adherence-heatmap'
import { findLastFinishedPack } from '../../utils/packs'
import patientsService from '../../api-services/patients-service'
import packsService from '../../api-services/packs-service'
import { getPatientReport } from '../../api-services/salesforce-service'
import DetailsBox from '../commons/details-box/details-box'
import Pack from '../commons/pack/pack'
import { serverErrorToast, errorToast } from '../layout/toaster/toaster'
import css from './reports.module.css'

function Report ({ match }) {
  const { id } = match.params
  const [patient, setPatient] = useState(null)

  async function fetchData () {
    try {
      const patient = await patientsService.fetchOneById(id)
      if (!patient.salesforceId) return errorToast("Patient doesn't have Salesforce ID")

      patient.packs = await packsService.fetch({ patientId: patient._id })
      
      const salesforce = await getPatientReport({ contactId: patient.salesforceId, from: moment().add(-7, 'd').format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') })
      patient.sf = salesforce.client
      patient.introCall = salesforce.introCall
      patient.cases = salesforce.cases.records.filter(c => c.Behavior_Scenario__c || c.Ability_Scenario__c).sort((a, b) => (a.CreatedDate > b.CreatedDate) ? 1 : -1)
      setPatient(patient)
      window.print()
    } catch (err) {
      serverErrorToast(err)
    }
  }

  useEffect(() => {
    fetchData()
  }, [id])

  if (!patient) return <h1>Loading...</h1>

  const name = `${patient.firstName} ${patient.lastName}`
  const pack = findLastFinishedPack(patient.packs)
  const adherenceTarget = patient.sf.Adherence_Target__c || 80

  let status
  if (patient.introCall) {
    status = []
    if (patient.introCall.AtHomeSupport__c === 'Yes') status.push('At Home Support')
    if (patient.introCall.LiveAlone__c === 'Yes') status.push('Lives Alone')
    if (patient.introCall.NearbySupport__c === 'Yes') status.push('Nearby Support')
    status.push(`Schedule Appointments: ${patient.introCall.ScheduledAppointments__c}`)
    status = status.join(', ')
  }

  return (
    <div className={css.report}>
      <h1>CuePath Adherence Report for {name}</h1>
      <DetailsBox>
        <DetailsBox.Item text='Client Name' value={name} striped />
        <DetailsBox.Item text='Client ID' value={patient.medId} striped />
        <DetailsBox.Item text='Age' value={moment().diff(patient.dateOfBirth, 'years')} striped />
        <DetailsBox.Item text='Status' value={status} striped />
        <DetailsBox.Item text='Adherence Threshold' value={`${patient.sf.adherenceMissThreshold__c} per ${patient.sf.adherenceMissWindow__c}`} striped />
        <DetailsBox.Item text='Adherence Target' value={`${adherenceTarget}%`} striped />
      </DetailsBox>
      <p style={{ marginTop: 30 }}>Your client, <b>{patient.sf.Salutation} {name}</b>, {patient.sf.X30_Days_Adherence_Score__c ? <>has a 30 day adherence score of <b>{patient.sf.X30_Days_Adherence_Score__c}%</b>, which is {patient.sf.X30_Days_Adherence_Score__c < adherenceTarget ? 'below' : 'above'} target, and </> : 'has'} a 7-day adherence score of <b>{patient.sf.X7_Days_Adherence_Score__c}%</b>, which is {patient.sf.X7_Days_Adherence_Score__c < adherenceTarget ? 'below' : 'above'} target.</p>
      <p>This week, <b>{patient.sf.Salutation} {name}</b> had <b>{patient.cases.length}</b> adherence support calls.</p>
      { patient.cases.length > 0 && <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Call Summary</th>
          </tr>
        </thead>
        <tbody>
          {patient.cases.map(c => <tr key={c.CreatedDate}>
            <td>{moment(c.CreatedDate).format('DD MMM YYYY')}</td>
            <td>
              <div><b>Behavior:</b> {c.Behavior_Scenario__c}</div>
              <div><b>Ability:</b> {c.Ability_Scenario__c}</div>
              <div><b>Prompt:</b> {c.Prompt_Scenario__c}</div>
              <br />
              <div><b>Call Notes:</b></div>
              <div dangerouslySetInnerHTML={{ __html: c.Adherence_Scenario_Comments__c }} />
            </td>
          </tr>)}
        </tbody>
      </table> }
      { pack ? <>
        <h2 style={{ pageBreakBefore: 'always' }}>{name}: Adherence Behaviour Summary</h2>
        <Pack pack={pack} options={{ timezone: patient.timezone, viewOnly: true }} />
        <h2 style={{ pageBreakBefore: 'always' }}>{name}: Missed Dose Patterns (Last 30 Days)</h2>
        <AdherenceHeatmap
          packs={patient.packs}
          from={moment().add(-30, 'd')} />
        </> : 'No finished packs'}
    </div>
  )
}

export default Report

import config from '../config'
import GenericApiService from './generic-api-service'

const baseURL = config.urls.organizations

const getAllChildren = (node, list) => {
  let children = []
  for (const item of list) {
    if(node._id === item.parentId) {
      children = [...children, item._id, ...getAllChildren(item, list)]
    }
  }
  return children
}

const populateOrganizations = (organizations, patientsService) => {
  const map = {}
  patientsService.data.forEach(p => {
    /**
     * For now show only patients related to org as hcaId (ignore pharmacyId relation)
     */
    // if (p.pharmacyId) {
    //   if (!map[p.pharmacyId]) map[p.pharmacyId] = []
    //   map[p.pharmacyId].push(p)
    if (p.hcaId) {
      if (!map[p.hcaId]) map[p.hcaId] = []
      map[p.hcaId].push(p)
    }
  })

  organizations.forEach(organization => {
    organization.children = getAllChildren(organization, organizations)
    
    organization.patients = map[organization._id] || []
    organization.children.forEach(orgId => {
      if (map[orgId]) organization.patients = [...organization.patients, ...map[orgId]]
    })
    organization.patients = [...new Set(organization.patients)]
    organization.nPatients = organization.patients.length
  })

  return organizations.reverse()
}

const organizationsService = new GenericApiService(baseURL, '_id', [], populateOrganizations)

export default organizationsService

import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Text, Classes, H6 } from '@blueprintjs/core'
import css from './details-box.module.css'

function DetailsBoxItem ({ text, value, striped, href }) {
  if (value === true) value = 'Yes'
  if (value === false) value = 'No'
  if (!value) return null

  let itemValue
  if (href) itemValue = <Link to={href} className={Classes.TEXT_LARGE}>{value}</Link>
  else itemValue = <span className={Classes.TEXT_LARGE}>{value}</span>

  return (
    <Text className={classNames(css.item, { [css.striped]: striped })}>
      <H6 className={classNames(Classes.TEXT_MUTED, css.itemText)}>{text}</H6>
      {itemValue}
    </Text>
  )
}

export default DetailsBoxItem

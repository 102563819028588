import React from 'react'
import { Classes, Text } from '@blueprintjs/core'

function SkeletonTableRows ({ nRows, nColumns }) {
  const columns = []
  for (let i = 0; i < nColumns; i++) {
    columns.push(<td key={i}><Text className={Classes.SKELETON}>_</Text></td>)
  }
  const rows = []
  for (let i = 0; i < (nRows || 5); i++) {
    rows.push(<tr key={i}>{columns}</tr>)
  }
  return <table><tbody>{rows}</tbody></table>
}

export default SkeletonTableRows

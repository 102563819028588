import authenticationServices from '../../authentication-services'
import axios from 'axios'
import config from '../../config'

async function exportCSV (patient) {
  const patientId = patient._id;
  const patientClinic = patient.patientClinic;
  const timezone = patient.timezone;

  const filePath = "export_" + patientId + ".csv";

  //console.log(`Bearer ${authenticationServices.authenticationData.accessToken}`)
  //console.log("baseUrl: " + config.urls.packs)

  const data =  {
    "patientId":patientId,
    "patientClinic": patientClinic,
    "timezone": timezone
  }

  const result = await axios.post(config.urls.packs + "/exportCSV",
    data,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authenticationServices.authenticationData.accessToken}`
      }
  }).then((response) => {

    if (response.status === 200) {
      var FileSaver = require('file-saver');
      var blob = new Blob([response.data], { type:"text/csv"});

      FileSaver.saveAs(blob, filePath);
    } else {
      alert("Failed to export csv file for sticker id mapping!");
    }
  });

  return result;
}

export default exportCSV
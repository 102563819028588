import React, { useState } from 'react'
import { H4 } from '@blueprintjs/core'
import DashboardElement from '../layout/dashboard-element/dashboard-element'
import GatewaysTable from './gateways-table/gateways-table'
import { useList } from '../custom-hooks/api-hooks'
import gatewaysService from '../../api-services/gateways-service'
import css from './devices.module.css'

function Gateways () {
  const [gateways, setGateways] = useState()
  useList(gatewaysService, setGateways)
  return (
    <DashboardElement>
      <div className={css.deviceTableHeader}>
        <H4 className={css.deviceTableHeadings}>List of all gateways</H4>
      </div>
      <div>
        <GatewaysTable gateways={gateways} />
      </div>
    </DashboardElement>
  )
}

export default Gateways

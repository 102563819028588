import React from 'react'
import classNames from 'classnames'
import { H4, Colors, Classes } from '@blueprintjs/core'
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts'
import DashboardElement from '../../layout/dashboard-element/dashboard-element'
import CustomLabel from './monitored-patients-chart-custom-label'
import css from './monitored-patients-chart.module.css'

const COLORS = [Colors.GREEN3, Colors.RED3]

function MonitoredPatientsChart ({ activePatients }) {
  const dataSet = activePatients && activePatients.reduce((result, patient, key) => {
    patient.currentPack && patient.currentPack.isConnected ? result[0].value++ : result[1].value++
    return result
  }, [{ name: 'Monitored', value: 0 }, { name: 'Unmonitored', value: 0 }])
  const Cells = dataSet && dataSet.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} key='index' />)
  return (
    <DashboardElement className={css.monitoredPatientsChart}>
      <H4>Monitored Patients</H4>
      <div className={classNames(css.chartWrapper, { [Classes.SKELETON]: !dataSet })}>
        <ResponsiveContainer>
          <PieChart>
            <Tooltip />
            <Pie data={dataSet} labelLine={false} label={CustomLabel} dataKey='value'>
              {Cells}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </DashboardElement>
  )
}

export default MonitoredPatientsChart

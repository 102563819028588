import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Dashboard from './dashboard/dashboard'
import Organizations from './organizations/organizations'
import Organization from './organizations/organization-navigation-wrapper'
import Patients from './patients/patients'
import Patient from './patients/patient-navigation-wrapper'
import PatientReport from './reports/patient'
import PatientPackReport from './reports/patient-mapped-packs'
import OrganizationReport from './reports/organization'
import Gateways from './devices/gateways'
import NotFound from './layout/not-found/not-found'
import SessionManager from './session-manager/session-manager'
import authenticationServices from '../authentication-services'
import Layout from './layout/layout'
import GlobalContext, { defaultState } from '../global-context'
import { useAuthentication } from './custom-hooks/authentication-hooks'

function App () {
  const [ globalState ] = useState(defaultState)
  const authenticationData = useAuthentication()
  const user = authenticationData.user || {}
  const role = authenticationData.role

  return (
    <GlobalContext.Provider value={globalState}>
      <Router>
        <Route path='/' component={SessionManager} />
        <Route path='/auth0_callback' render={() => <Redirect to={authenticationServices.getPreviousRoute()} />} />
        { role && <Switch>
          <Route path='/report-organization/:id' component={OrganizationReport} />
          <Route path='/report-patient-pack/:id' component={PatientPackReport} />
          <Route path='/report-patient/:id' component={PatientReport} />

          <Layout userName={user.name} userAvatar={user.picture} role={authenticationData.role} logoutHandler={authenticationServices.logout}>
            <Switch>
              <Route exact path='/' render={() => <Redirect to={role === 'admin' ? '/dashboard' : '/patients'} />} />
              <Route path='/dashboard' component={Dashboard} />
              <Route path='/organizations/:id?/edit' component={Organization} />
              <Route path='/organizations/:id' component={Organization} />
              <Route path='/organizations' component={Organizations} />
              <Route path='/patients/:id' component={Patient} />
              <Route path='/patients' component={Patients} />
              <Route path='/gateways' component={Gateways} />
              <Route component={NotFound} />
            </Switch>
          </Layout>
        </Switch> }
      </Router>
    </GlobalContext.Provider>
  )
}

export default App

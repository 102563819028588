import React from 'react'
import moment from 'moment-timezone'
import breakfastIcon from './images/breakfast.svg'
import lunchIcon from './images/lunch.svg'
import supperIcon from './images/supper.svg'
import bedtimeIcon from './images/bedtime.svg'
import css from './styles.module.css'

const columnKey2Icon = {
  column0: breakfastIcon,
  column1: lunchIcon,
  column2: supperIcon,
  column3: bedtimeIcon
}

const columnKey2Name = {
  column0: 'Breakfast',
  column1: 'Lunch',
  column2: 'Supper',
  column3: 'Bedtime'
}

const columnKey2Week = {
  column0: 'Week A',
  column1: 'Week B',
  column2: 'Week C',
  column3: 'Week D'
}

function ColumnIcon ({ columnKey }) {
  const icon = columnKey2Icon[columnKey]
  const alt = `${columnKey2Name[columnKey]} icon`
  return <img src={icon} alt={alt} />
}

function Timeframe ({ column, timezone }) {
  let lastEnabledRow
  for (let i = 0; i < 7; i++) {
    const row = column[`row${i}`]
    if(row.enabled) {
      if(!lastEnabledRow) lastEnabledRow = row
      else if(new Date(row.dateToOpenFrom) > new Date(lastEnabledRow.dateToOpenFrom)) lastEnabledRow = row
    }
  }
  let text = '-'
  if (lastEnabledRow) {
    text = `${moment(lastEnabledRow.dateToOpenFrom).tz(timezone).format('hA')} - ${moment(lastEnabledRow.dateToOpenTo).tz(timezone).format('hA')}`
  }
  return <span className={css.columnHeaderText}>{text}</span>
}

function ColumnName ({ columnKey, showDoses }) {
  return <span className={css.columnHeaderText}>{showDoses ? columnKey2Name[columnKey] : columnKey2Week[columnKey]}</span>
}

function ColumnHeader ({ column, columnKey, timezone, type }) {
  const showDoses = type !== 'thrifty28days'
  if (!column) return <div className={css.columnHeader} />
  return (
    <div className={css.columnHeader}>
      { showDoses && <ColumnIcon columnKey={columnKey} />}
      <Timeframe column={column} timezone={timezone} />
      <ColumnName columnKey={columnKey} showDoses={showDoses} />
    </div>
  )
}

export default ColumnHeader

import { useEffect } from 'react'

function useList (apiService, dataHandler, deps) {
  useEffect(() => {
    dataHandler(apiService.data)
    const subscription = apiService.subscribe(dataHandler)
    return () => apiService.unsubscribe(subscription)
  }, deps || [])
}

function useOne (apiService, id, dataHandler) {
  const handler = () => dataHandler(apiService.findOneById(id) ? { ...apiService.findOneById(id) } : null)
  useList(apiService, handler, [id])
  useEffect(handler, [id])
}

export { useList, useOne }

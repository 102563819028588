import axios from 'axios'
import config from '../config'
import authenticationServices from '../authentication-services'

const baseURL = config.urls.gatewayEvents

const api = axios.create({
  baseURL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json'
  }
})

async function fetch (params = {}) {
  params.limit = params.limit || 100
  params.skip = params.skip || 0
  params.sort = params.sort || '-createdAt'
  const headers = {
    Authorization: `Bearer ${authenticationServices.authenticationData.accessToken}`
  }
  const response = await api.get('', { params, headers })
  return response.data
}

export default {
  fetch
}

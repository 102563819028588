import axios from 'axios'
import config from '../config'
import authenticationServices from '../authentication-services'

const baseURL = config.urls.salesforce

const api = axios.create({
  baseURL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json'
  }
})
api.interceptors.request.use(request => {
  request.headers.Authorization = `Bearer ${authenticationServices.authenticationData.accessToken}`
  return request
})

async function syncSalesforce () {
  const response = await api.post('sync', {})
  return response.data
}

async function getPatientReport (params) {
  const response = await api.get('patient-report', { params })
  return response.data
}

async function getOrganizationReport (id) {
  const response = await api.get(`organization-report/${id}`)
  return response.data
}

export {
  syncSalesforce,
  getPatientReport,
  getOrganizationReport
}

import React from 'react'
import classNames from 'classnames'
import DashboardElement from '../../layout/dashboard-element/dashboard-element'
import PatientsTable from '../../commons/patients-table/patients-table'
import css from './active-patients-table.module.css'

function ActivePatientsTable ({ activePatients, className, style }) {
  return (
    <DashboardElement className={classNames(css.activePatientsTable, className)} style={style}>
      <PatientsTable patients={activePatients} />
    </DashboardElement>
  )
}

export default ActivePatientsTable

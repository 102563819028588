import React from 'react'
import css from './fixed-ratio-responsive-container.module.css'

/*
  Unfortunately, there is no way to make the height to be a function of the width out of the box (as of 2019).
  So, if we want to make a responsive element with a fixed aspect ratio, it can be quite tricky
  and the code ends up looking hacky either through css or through js.

  The way to do it through css is by using relative padding. A brief explanation:
  The relative padding is always relative to the width of the element (doesn't matter if it is a vertical or a horizontal padding).

  .widthWrapper is actually defining the element dimensions except for the height.
  .heightWrapper is setting the height (with padding) relative to the widthWrapper's width. The reason We need those two elements,
    is because, depending on how the element's parent is positioned (also things like flex, float, etc...), if we put all this css in .widthWrapper,
    the height could be relative to the parent's width, not the widthWrapper's width.
  .container has `position: absolute`. The trick here is that although the .heightWrapper element is empty, the padding is accounted for the total height,
    and if we use absolute position .container doesn't count as a content of .heightWrapper (i.e. doesn't make it grow) but still uses height and width of its parent as a reference,
    so we can fill 100% of of the height and width.
*/

function FixedRatioResponsiveContainer ({ width, heightOverWidthRatio, minWidth, maxWidth, children }) {
  const widthWrapperStyle = { width, minWidth, maxWidth }
  const heightWrapperStyle = { paddingBottom: `${heightOverWidthRatio * 100}%` }
  return (
    <div className={css.widthWrapper} style={widthWrapperStyle}>
      <div className={css.heightWrapper} style={heightWrapperStyle}>
        <div className={css.container}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default FixedRatioResponsiveContainer

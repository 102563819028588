import React, { useState } from 'react'
import { H4, Button, Intent, Dialog } from '@blueprintjs/core'
import DashboardElement from '../layout/dashboard-element/dashboard-element'
import PatientsTable from '../commons/patients-table/patients-table'
import patientsService from '../../api-services/patients-service'
import authenticationServices from '../../authentication-services'
import { useList } from '../custom-hooks/api-hooks'
import css from './patients.module.css'
import FlexRow from '../layout/flex-row/flex-row'
import PatientForm from './patient-form'

function Patients () {
  const [patients, setPatients] = useState()
  const [isDialogOpen, setDialogOpen] = useState(false)
  useList(patientsService, setPatients)
  const { role } = authenticationServices.authenticationData

  return (
    <div>
      <DashboardElement className={css.patientsTable}>
        <FlexRow style={{ justifyContent: 'space-between' }}>
          <H4>List of all patients</H4>
          { role === 'admin' && <Button intent={Intent.PRIMARY} icon='plus' className={css.addButton} onClick={() => setDialogOpen(true)}>Add Patient</Button>}
        </FlexRow>
        <PatientsTable patients={patients} />
        <Dialog
          isOpen={isDialogOpen}>
          <PatientForm setDialogOpen={setDialogOpen} />
        </Dialog>
      </DashboardElement>
    </div>
  )
}

export default Patients

import React, { useState } from 'react'
import organizationsService from '../../api-services/organizations-service'
import { useList, useOne } from '../custom-hooks/api-hooks'
import NavigationContainer from '../layout/navigation-container/navigation-container'
import EntitiesNavigation from '../commons/entities-navigation/entities-navigation'
import NotFound from '../layout/not-found/not-found'
import Organization from './organization'
import OrganizationForm from './organization-form'

function OrganizationNavigationWrapper ({ match }) {
  const { id } = match.params
  const [organizations, setOrganizations] = useState()
  const [organization, setOrganization] = useState()
  useList(organizationsService, setOrganizations)
  useOne(organizationsService, id, setOrganization)

  if (id && !organization) return <NotFound />

  const entities = organizations && organizations.map(organization => ({
    text: organization.name,
    secondaryText: `${organization.status} - ${organization.nPatients} patients`,
    href: `/organizations/${organization._id}`
  }))

  return (
    <NavigationContainer menu={<EntitiesNavigation entities={entities} title='Organizations' />} menuElevation='0'>
      { match.path.includes('edit')
        ? <OrganizationForm organization={organization} />
        : <Organization organization={organization} />
      }
    </NavigationContainer>
  )
}

export default OrganizationNavigationWrapper

import React, { useState } from 'react'
import { useList } from '../custom-hooks/api-hooks'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, Intent, H4, ButtonGroup, Alert } from '@blueprintjs/core'
import organizationsService from '../../api-services/organizations-service'
import authenticationServices from '../../authentication-services'
import usersService from '../../api-services/users-service'
import DashboardElement from '../layout/dashboard-element/dashboard-element'
import DetailsBox from '../commons/details-box/details-box'
import FormElement from '../commons/form-group/form-group'
import PatientsTable from '../commons/patients-table/patients-table'
import { serverErrorToast, successToast } from '../layout/toaster/toaster'

function Organization ({ organization }) {
  const { role } = authenticationServices.authenticationData
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [isInviteOpen, setIsInviteOpen] = useState(false)
  const [organizations, setOrganizations] = useState()
  useList(organizationsService, setOrganizations)
  const { register, handleSubmit, errors, reset } = useForm()
  const history = useHistory()

  const deleteOrganization = async () => {
    try {
      await organizationsService.delete(organization._id)
      successToast(`Organization was successfully removed`)
      history.push('/organizations')
    } catch (err) {
      serverErrorToast(err)
      setIsDeleteOpen(false)
    }
  }

  const onInviteUser = async ({ name, email }) => {
    try {
      await usersService.post({
        name,
        email,
        organizationId: organization._id,
        organizationName: organization.name,
      })
      successToast(`User was successfully invited by email: ${email}`, null)
      setIsInviteOpen(false)
      reset()
    } catch (err) {
      serverErrorToast(err)
    }
  }

  const { _id, name, timezone, createdAt, salesforceId, type, shortId } = organization
  const { contactName, email, phone, address, city, state, country, zip } = {}
  const parent = organizations ? organizations.find(o => o._id === organization.parentId) : null

  return (
    <>
      <DashboardElement style={{ height: 'fit-content' }}>
        <DetailsBox title={`${name}`}
          primaryActionButton={
            <ButtonGroup>
              <Button icon='print' onClick={() => window.open(`/report-organization/${_id}`, '_blank')}>Report</Button>
              { role === 'admin' &&
                <>
                  <Button intent={Intent.PRIMARY} icon='edit' onClick={() => history.push(`/organizations/${_id}/edit`)}>Edit</Button>
                  <Button intent={Intent.WARNING} icon='new-person' onClick={() => setIsInviteOpen(true)}>Invite</Button>
                  <Button intent={Intent.DANGER} icon='trash' onClick={() => setIsDeleteOpen(true)}>Delete</Button>
                </> }
            </ButtonGroup>
          }>
          <DetailsBox.Item text='Type' value={type} />
          <DetailsBox.Item text='Rx/HCA ID' value={shortId} />
          { role !== 'pharmacy' && <DetailsBox.Item text='Salesforce ID' value={salesforceId} />}
          <DetailsBox.Item text='Parent' value={parent && <Link to={`/organizations/${parent._id}`}>{parent.name}</Link>} />
          <DetailsBox.Item text='Contact Name' value={contactName} />
          <DetailsBox.Item text='Phone' value={phone} />
          <DetailsBox.Item text='Email' value={email} />
          <DetailsBox.Item text='Address' value={city ? `${city}, ${state} ${address}, ${zip}, ${country}` : null} />
          <DetailsBox.Item text='Timezone' value={timezone} />
          <DetailsBox.Item text='Created At' value={createdAt && createdAt.toString()} />
        </DetailsBox>
      </DashboardElement>
      <DashboardElement style={{ height: 'fit-content' }}>
        <H4>Patients List</H4>
        <PatientsTable patients={organization.patients} />
      </DashboardElement>
      <Alert
        cancelButtonText='Cancel'
        confirmButtonText='Delete Organization'
        icon='trash'
        intent={Intent.DANGER}
        isOpen={isDeleteOpen}
        onCancel={() => setIsDeleteOpen(false)}
        onConfirm={deleteOrganization}
      >
        <p>Are you sure you want to permanently delete "{name}" organization?</p>
      </Alert>
      <Alert
        cancelButtonText='Cancel'
        confirmButtonText='Invite User'
        icon='new-person'
        intent={Intent.SUCCESS}
        isOpen={isInviteOpen}
        onCancel={() => setIsInviteOpen(false)}
        onConfirm={handleSubmit(onInviteUser)}
      >
        <p>Please add name and email of a person to access "{name}" organization.</p>
        <form>
          <FormElement name='name' inputRef={register({ required: true })} label='Name' errors={errors} />
          <FormElement name='email' inputRef={register({ required: true, pattern: /\S+@\S+\.\S+/ })} label='Email' errors={errors} />
        </form>    
      </Alert>
    </>
  )
}

export default Organization

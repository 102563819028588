import React from 'react'
import moment from 'moment'
import { Popover } from '@blueprintjs/core'
import DetailsBox from '../details-box/details-box'

const getLastTransmissionTime = lastTransmission => {
  return lastTransmission ? moment.duration(moment().diff(moment(lastTransmission))).humanize() : 'N/A'
}

function GatewayDetailsPopover ({ gateway, children }) {
  if (!gateway) return null
  const content = (
    <div style={{ padding: '15px', paddingTop: ' 10px' }}>
      <DetailsBox title={`Gateway: ${gateway.gatewayId}`}>
        <DetailsBox.Item text='Battery Level' value={gateway.batteryLevel}></DetailsBox.Item>
        <DetailsBox.Item text='Last Transmission' value={getLastTransmissionTime(gateway.lastMessageTime)}></DetailsBox.Item>
        <DetailsBox.Item text='Is Connected' value={gateway.isConnected}></DetailsBox.Item>
      </DetailsBox>
    </div>
  )
  return <Popover boundary='window' modifiers={{ flip: true, preventOverflow: true }} content={content} target={children} />
}

export default GatewayDetailsPopover

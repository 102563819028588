import React from 'react'
import moment from 'moment-timezone'
import classnames from 'classnames'
import css from './styles.module.css'
import { Popover } from '@blueprintjs/core'
import DetailsBox from '../details-box/details-box'
import batteryIcon from '../../../images/battery.svg'

function formatStartDate (startDate, endDate) {
  if (!startDate || !endDate) return ''
  if (startDate.year() !== endDate.year()) return startDate.format('D MMM YYYY')
  if (startDate.month() !== endDate.month()) return startDate.format('D MMM')
  return startDate.format('D')
}

const getLastTransmissionTime = lastTransmission => {
  return lastTransmission ? moment.duration(moment().diff(moment(lastTransmission))).humanize() : 'N/A'
}

function ConnectivityStatus ({ status, isConnected, batteryLevel }) {
  return (
    <div className={classnames(css.statusCircle, css[status], { [css.monitored]: isConnected, [css.notMonitored]: !isConnected })}>
      <img className={classnames(css.statusBattery)} src={batteryIcon} alt='' />
      <div className={classnames(css.statusMessage)}>
        {batteryLevel}
      </div>
    </div>
  )
}

function PackHeader ({ pack, timezone }) {
  const { packId, status, isConnected, batteryLevel, signalStrength, lastMessageTime, type } = pack
  const startDate = pack.startDate && moment(pack.startDate).tz(timezone)
  const endDate = pack.endDate && moment(pack.endDate).tz(timezone)

  const content = (
    <div style={{ padding: '15px', paddingTop: ' 10px' }}>
      <DetailsBox title={`Pack: ${packId}`}>
        <DetailsBox.Item text='Pack Type' value={type}></DetailsBox.Item>
        <DetailsBox.Item text='Battery Level' value={batteryLevel}></DetailsBox.Item>
        <DetailsBox.Item text='Signal Strength' value={signalStrength}></DetailsBox.Item>
        <DetailsBox.Item text='Last Transmission' value={getLastTransmissionTime(lastMessageTime)}></DetailsBox.Item>
        <DetailsBox.Item text='Is Connected' value={isConnected}></DetailsBox.Item>
      </DetailsBox>
    </div>
  )

  return (
    <>
      <ConnectivityStatus status={status} isConnected={isConnected} batteryLevel={batteryLevel} />
      <h2 className={classnames(css.header, css[status])}>
        <Popover boundary='window' modifiers={{ flip: true, preventOverflow: true }} content={content}>
          {startDate ? `${formatStartDate(startDate, endDate)} - ${endDate.format('D MMM YYYY')}` : 'Prepared'}
        </Popover>
      </h2>

    </>
  )
}

export default PackHeader

import React from 'react'
import { InputGroup, Intent, FormGroup, Colors } from '@blueprintjs/core'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

import './form-group.css'
import 'react-datepicker/dist/react-datepicker.css'

const getErrorMessage = (type, label) => {
  switch (type) {
    case 'required':
      return `${label} is required`
    case 'pattern':
      return `${label} should match pattern`
    default:
      return `${label} is not valid`
  }
}

function Input (props) {
  const { type, errors, name, control, rules, defaultValue } = props

  const newProps = { ...props }
  delete newProps.label
  delete newProps.labelInfo
  delete newProps.errors
  delete newProps.type
  delete newProps.control

  if (type && type === 'date') {
    return <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, onBlur, value }) => (
        <DatePicker
          {...newProps}
          className='bp3-input'
          onChange={onChange}
          onBlur={onBlur}
          selected={value}
        />
      )}
    />
  }

  if (props.options) {
    return <Controller
      control={control}
      as={Select}
      {...newProps}
    />
  }

  return <InputGroup
    {...newProps}
    intent={errors && errors[name] ? Intent.DANGER : Intent.NONE}
  />
}

function FormElement (props) {
  const { label, labelInfo, errors, name } = props

  return <FormGroup label={label} labelInfo={labelInfo}>
    <Input {...props} />
    { errors && errors[name] && <div style={{ color: Colors.VERMILION3, marginTop: '5px' }}>{getErrorMessage(errors[name].type, label)}</div>}
  </FormGroup>
}

export default FormElement

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import organizationService from '../../api-services/organizations-service'
import patientsService from '../../api-services/patients-service'
import { getOrganizationReport } from '../../api-services/salesforce-service'
import { serverErrorToast, errorToast } from '../layout/toaster/toaster'
import css from './reports.module.css'
import packsService from '../../api-services/packs-service'

const startOfLastWeek = moment().subtract(1, 'weeks').startOf('isoWeek')
const startOfWeekBeforelast = moment().subtract(2, 'weeks').startOf('isoWeek')

const getRatingAndColor = (score, target) => {
  if(!score) return { rating: '', color: '#fff'}
  if (score > target) return { rating: 'Good', color: '#e1ffd7'}
  if (score < (target - (100 - target))) return { rating: 'Poor', color: '#ffd7d7'}
  return { rating: 'Fair', color: '#feffd7'}
}

function Report ({ match }) {
  const { id } = match.params
  const [data, setData] = useState(null)

  async function fetchData () {
    try {
      const organization = await organizationService.fetchOneById(id)
      if (!organization.salesforceId) return errorToast("organization doesn't have Salesforce ID")

      const data = {
        name: organization.name,
        totalPassedPacks: 0,
        lastWeekAdded: 0,
        lastWeekTerminated: 0,
        activePatients: []
      }

      /**
       * Reports should show only patients related to org as hcaId, pharmacyId will be ignored
       */
      const patients = (await patientsService.fetch()).filter(p => p.salesforceId && p.hcaId === id)
      const { clients } = await getOrganizationReport(organization._id)
      const allPacks = await packsService.fetch({ patientId: patients.map(p => p._id) })

      patients.forEach(p => {
        const packs = allPacks.filter(pack => pack.patientId === p._id && pack.status !== 'prepared')
        const packsFromLastWeek = packs.filter(pack => moment(pack.startDate).isAfter(startOfLastWeek))
        const packsFromWeekBeforeLast = packs.filter(pack => moment(pack.startDate).isBetween(startOfWeekBeforelast, startOfLastWeek))
        if (packsFromLastWeek.length) {
          if (packsFromLastWeek.length === packs) data.lastWeekAdded++
          p.sf = clients.find(c => c.Id === p.salesforceId) 
          data.totalPassedPacks += packs.length
          data.activePatients.push(p)
        } else if (packsFromWeekBeforeLast.length) data.lastWeekTerminated++
      })

      data.averageDuration = data.activePatients.length && data.totalPassedPacks / data.activePatients.length
      
      setData(data)
      window.print()
    } catch (err) {
      serverErrorToast(err)
    }
  }

  useEffect(() => {
    fetchData()
  }, [id])

  if (!data) return <h1>Loading...</h1>


  return (
    <div className={css.report}>
      <h1>CuePath Adherence Report - {data.name}</h1>
      <h3 style={{ marginTop: 30 }}>Clients Cohort Adherence Summary</h3>
      { data.activePatients.length > 0 && <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Threshold</th>
            <th>7 Days Score</th>
            <th>30 Days Score</th>
            <th>Rating</th>
          </tr>
        </thead>
        <tbody>
          {data.activePatients.map(p => {
          const adherenceTarget = p.sf.Adherence_Target__c || 80
          const days7Rating = getRatingAndColor(p.sf.X7_Days_Adherence_Score__c, adherenceTarget)
          const days30Rating = getRatingAndColor(p.sf.X30_Days_Adherence_Score__c, adherenceTarget)

          return <tr key={p.medId}>
            <td>{p.firstName} {p.lastName}</td>
            <td>{p.sf.adherenceMissThreshold__c} per {p.sf.adherenceMissWindow__c}</td>
            <td style={{backgroundColor: days7Rating.color}}>{p.sf.X7_Days_Adherence_Score__c}</td>
            <td style={{backgroundColor: days30Rating.color}}>{p.sf.X30_Days_Adherence_Score__c}</td>
            <td style={{backgroundColor: days7Rating.color}}>{days7Rating.rating}</td>
          </tr>})}
        </tbody>
      </table> }
      <h3 style={{ marginTop: 30 }}>Clients Cohort Business Summary</h3>
      <table>
        <tbody>
          <tr>
            <td width='25%'>Total Active Clients on CuePath</td>
            <td>{data.activePatients.length}</td>
          </tr>
          <tr>
            <td>Average Client Use Duration (Weeks)</td>
            <td>{data.averageDuration}</td>
          </tr>
          <tr>
            <td>Last Week Subscriptions Added</td>
            <td>{data.lastWeekAdded}</td>
          </tr>
          <tr>
            <td>Last Week Subscriptions Terminated</td>
            <td>{data.lastWeekTerminated}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Report

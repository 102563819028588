import React, { useState } from 'react'
import classnames from 'classnames'
import { Button, Alert, Intent, Classes } from '@blueprintjs/core'
import FixedRatioResponsiveContainer from '../../layout/fixed-ratio-responsive-container/fixed-ratio-responsive-container'
import GatewayEventsPopover from '../gateway-events/gateway-events-popover'
import gatewayEventsService from '../../../api-services/gateway-events-service'
import packsService from '../../../api-services/packs-service'
import patientsService from '../../../api-services/patients-service'
import { serverErrorToast, successToast } from '../../layout/toaster/toaster'
import authenticationServices from '../../../authentication-services'
import PackHeader from './pack-header'
import PackBody from './pack-body'
import css from './styles.module.css'

function Card ({ status, isConnected, children }) {
  const statusClasses = {
    [css.notMonitored]: !isConnected,
    [css.active]: status === 'active'
  }
  return <div className={classnames(css.card, statusClasses)}>{children}</div>
}

function Pack ({ pack, options = {} }) {
  const timezone = options.timezone || 'UTC'
  const { status, isConnected, packId, stickerId, cavities } = pack
  if (!pack || !cavities) return null

  const [isDeletePackOpen, setIsDeletePackOpen] = useState(false)
  const [gatewayEvents, setGatewayEvents] = useState(null)
  const [isDeletePackWaiting, setIsDeletePackWaiting] = useState(false)

  const { role } = authenticationServices.authenticationData

  const fetchGatewayEvents = async () => {
    const events = await gatewayEventsService.fetch({ packId })
    setGatewayEvents(events)
  }

  async function handleDelete () {
    setIsDeletePackWaiting(true)
    setIsDeletePackOpen(false)
    try {
      await packsService.delete(pack._id)
      patientsService.populate()
      successToast('Pack successfully deleted.')
    } catch (err) {
      serverErrorToast(err)
    }
    setIsDeletePackWaiting(false)
  }

  if (isDeletePackWaiting) {
    return (
      <FixedRatioResponsiveContainer width='100%' maxWidth='550px' minWidth='445px' heightOverWidthRatio={1.5}>
        <div className={Classes.SKELETON} style={{ width: '100%', height: '100%' }} />
      </FixedRatioResponsiveContainer>
    )
  }

  return (
    <FixedRatioResponsiveContainer width='100%' maxWidth='550px' minWidth='445px' heightOverWidthRatio={1.5}>
      <Alert
        cancelButtonText='Cancel'
        confirmButtonText='Remove'
        icon='trash'
        intent={Intent.DANGER}
        isOpen={isDeletePackOpen}
        onCancel={() => setIsDeletePackOpen(false)}
        onConfirm={handleDelete}
      >
        <p>Are you sure you want to remove this pack?</p>
      </Alert>
      <div className={css.container}>
        <div className={css.packId}>
          Pack: {packId} { stickerId ? <b> {stickerId} </b>: ""}
          { role === 'admin' && !options.viewOnly && <GatewayEventsPopover gatewayEvents={gatewayEvents}>
            <Button key='gateway-events-popup' icon='heat-grid' minimal onClick={fetchGatewayEvents} />
          </GatewayEventsPopover>}
        </div>
        <Card status={status} isConnected={isConnected}>
          { role === 'admin' && !options.viewOnly && <Button icon='trash' style={{ zIndex: 10, position: 'absolute', top: 10, right: 10 }} minimal onClick={() => setIsDeletePackOpen(true)} />}
          <PackHeader pack={pack} timezone={timezone} />
          <PackBody pack={pack} timezone={timezone} />
        </Card>
      </div>
    </FixedRatioResponsiveContainer>
  )
}

export default Pack

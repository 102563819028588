import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { H4, Button, Intent } from '@blueprintjs/core'
import FlexRow from '../layout/flex-row/flex-row'
import organizationsService from '../../api-services/organizations-service'
import authenticationServices from '../../authentication-services'
import { useList } from '../custom-hooks/api-hooks'
import DashboardElement from '../layout/dashboard-element/dashboard-element'
import OrganizationsTable from '../commons/organizations-table/organizations-table'
import css from './organizations.module.css'

function Organizations () {
  const { role } = authenticationServices.authenticationData
  const [organizations, setOrganizations] = useState()
  useList(organizationsService, setOrganizations)

  return (
    <DashboardElement>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <H4>List of all organizations</H4>
        { role === 'admin' && <Link to={'/organizations/edit/'} className={css.addButton}>
          <Button intent={Intent.PRIMARY} icon='plus'>Add Organization</Button>
        </Link>}
      </FlexRow>
      <OrganizationsTable organizations={organizations} />
    </DashboardElement>
  )
}

export default Organizations
